import { shade } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  grid-area: ${(props) => props.gridArea};
  cursor: pointer;
  border-radius: 10px;

  background: ${(props) => props.theme.button.background};
  padding: 20px;
  border-radius: 18px;
  color: ${(props) => props.theme.text.inPrimary};
  font-weight: 800;
  transition: background-color 0.2s;

  &:hover {
    background: ${(props) => shade(0.2, props.theme.button.background)};
  }

  ${(props) =>
    props.gridArea === "cancel" &&
    css`
      background: ${(props) => props.theme.red};

      &:hover {
        background: ${(props) => shade(0.2, props.theme.red)};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: ${(props) => shade(0.2, props.theme.primary)};
      cursor: not-allowed;
    `}

  @media print {
    & {
      display: none !important;
    }
  }
`;
