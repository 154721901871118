import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FiChevronRight, FiPlus } from "react-icons/fi";

import { Menu } from "../../../components/Menu";
import { Header } from "../../../components/Header";
import { api } from "../../../services/api";
import { PopupAlert, PopupConfirm } from "../../../utils/PopupAlert";
import { Button } from "../../../components/NewButton";

import { format } from "date-fns";
import { Main, Title, Entity } from "./styles";

export default function Test() {
  const { patientId } = useParams();
  const { push } = useHistory();

  const [entities, setEntities] = useState([]);
  const [patient, setPatient] = useState();

  useEffect(() => {
    api.post(`/patient/findid`, { id: patientId }).then((response) => {
      setPatient({ ...response.data });
    });

    api.get(`/test`, { params: { patient_id: patientId } }).then((response) => {
      setEntities([...response.data]);
    });
  }, [patientId]);

  async function handleCreateTest() {
    const hasPendingTest = entities.find((entity) => !entity.finished);

    if (hasPendingTest) {
      return PopupAlert("Você precisa finalizar os testes anteriores", "error");
    }

    const confirmation = await PopupConfirm({
      title: "Criar teste",
      message: "Deseja realmente criar teste?",
      successMessage: "Sim",
      cancelMessage: "Não",
    });

    if (confirmation) {
      const response = await api.post("/test", { patient_id: patientId });

      push(`/patient/${patientId}/test/${response.data.id}`);
    }
  }

  const formattedEntities = useMemo(() => {
    return entities.map((entity) => ({
      ...entity,
      type: entity.type === "denver" ? "Denver" : "-",
      created_at: format(new Date(entity.created_at), "dd/MM/yyyy 'às' hh:mm"),
    }));
  }, [entities]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <span>
            <h2>Testes - {patient?.name}</h2>
          </span>

          <div>
            <Button onClick={handleCreateTest}>
              <FiPlus size={24} />
            </Button>
          </div>
        </Title>

        {formattedEntities.map((entity) => (
          <Entity
            key={entity.id}
            to={`/patient/${patientId}/test/${entity.id}`}
          >
            <div>
              {entity.id.toString().padStart(4, "0")} | Criado:{" "}
              {entity.created_at} | Tipo: {entity.type}
              {entity.finished && "| Finalizado"}
            </div>

            <FiChevronRight size={18} />
          </Entity>
        ))}
      </Main>
    </>
  );
}
