const theme = {
  primary: "#ffdab9",
  red: "#F2687F",
  yellow: "#F3C843",

  menu: {
    background: "#286083",
    text: "#FFFFFF",

    subMenu: {
      background: "#E9E9E9",
      hover: "#DBDBDB",
    },
  },

  button: {
    background: "#286083",
  },

  text: {
    default: "#353535",
    secondary: "#535353",
    label: "#C5C5C5",
    inPrimary: "#FFFFFF",
    sector: "#286083",
  },

  shape: {
    default: "#555555",
  },

  background: {
    default: "#F5F5F5",
    input: "#FBFBFB",
    box: "#E9E9E9",
  },
};

export default theme;
