import React, { useCallback, useContext } from "react";
import { FiPower, FiUser } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";

import logoImg from "../../assets/images/logo.png";
import { AuthContext } from "../../contexts/AuthContext";

import {
  Container,
  Content,
  UserInfos,
  ButtonSets,
  IconButton,
} from "./styles";

export function Header() {
  const history = useHistory();
  const { user, signOut } = useContext(AuthContext);

  const handleLogOut = useCallback(() => {
    const confirmation = window.confirm("Confirmar saída");
    if (confirmation) {
      signOut();
    }
  }, [signOut]);

  return (
    <Container>
      <Content>
        <UserInfos>
          <Link to="/main">
            <img src={logoImg} alt="Logo" />
          </Link>
        </UserInfos>

        <ButtonSets>
          <IconButton title="Usuário">
            <p onClick={() => history.push("/login/password")}>
              {user?.fullname}&nbsp;&nbsp;
              <FiUser size={20} />
            </p>
          </IconButton>
          &nbsp;
          <IconButton title="Sair">
            <p onClick={handleLogOut}>
              <FiPower size={20} />
            </p>
          </IconButton>
        </ButtonSets>
      </Content>
    </Container>
  );
}
