import styled from "styled-components";
import Tooltip from "../../components/Tooltip";

export const Container = styled.header`
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.button.background};
  display: flex;
  justify-content: center;
  padding: 0 16px;

  & ::selection {
    background: ${(props) => props.theme.text.inPrimary};
    color: ${(props) => props.theme.primary};
  }
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 15px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: bold;

  img {
    height: 75px;
    margin-right: 24px;
  }
`;

export const UserInfos = styled.div`
  display: flex;
  align-items: center;


  p {
    display: flex;
    align-items: center;

    svg {
      margin: 0 8px;
    }
  }
`;

export const ButtonSets = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    display: flex;
    align-items: center;
    padding: 3px;
  }
  svg {
    margin-right: 5px;
  }
`;

export const IconButton = styled(Tooltip)`
  a {
    color: ${(props) => props.theme.text.default};
    transition: color 0.2s;
    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;
