import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { format, parseISO } from "date-fns";
import { FiPlus, FiMinus } from "react-icons/fi";

import { api } from "../../services/api";
import { Header } from "../../components/Header";
import { Menu } from "../../components/Menu";
import { PopupAlert } from "../../utils/PopupAlert";
import { Button } from "../../components/NewButton";
import { Input } from "../../components/Input";
import { InputMask } from "../../components/InputMask";
import { Select } from "../../components/Select";
import { useHistory, useParams } from "react-router-dom";
import {
  Main,
  Title,
  EntityForm,
  PartnersTable,
  PartnersRow,
  TeamsTable,
  TeamsRow,
} from "./styles";

const optionsValue = [
  {
    value: "0",
    label: "---",
  },
  {
    value: "1",
    label: "Mãe",
  },
  {
    value: "2",
    label: "Pai",
  },
  {
    value: "3",
    label: "Avós",
  },
  {
    value: "4",
    label: "Responsável",
  },
];

const optionsPartnerValue = [
  {
    value: "0",
    label: "---",
  },
  {
    value: "1",
    label: "Fono",
  },
  {
    value: "2",
    label: "Neurologista",
  },
  {
    value: "3",
    label: "Pediatra",
  },
  {
    value: "4",
    label: "Pediatra",
  },
  {
    value: "5",
    label: "Psicólogo",
  },
  {
    value: "6",
    label: "Psiquiatra",
  },
  {
    value: "7",
    label: "Outros",
  },
];

export default function PatientAdd() {
  const { id } = useParams();
  const { goBack, push } = useHistory();
  const [partner, setPartner] = useState([]);
  const [optionsTeamValue, setOptionsTeamValue] = useState([]);
  const [team, setTeam] = useState([]);
  const { register, handleSubmit, setValue, getValues } = useForm();

  useEffect(() => {
    if (id) {
      api.post(`/patient/findid`, { id }).then((response) => {
        const entity = response.data;
        const formattedEntity = {
          ...entity,
          birthday: entity.birthday
            ? format(parseISO(entity.birthday), "yyyy-MM-dd")
            : "00/00/0000",
        };

        Object.keys(formattedEntity).forEach((key) => {
          const value = formattedEntity[key];
          if (value?.length > 0) setValue(key, value);
        });

        setPartner(entity.partner);
        setTeam(entity.team);
      });
    }
  }, [id, setValue]);

  useEffect(() => {
    api.get(`/expert`).then((response) => {
      setOptionsTeamValue(
        response.data.map((expert) => ({
          value: expert.id,
          label: `${expert.name} (${expert.specialty})`,
        }))
      );
    });
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await api.post(`/patient/add`, {
          ...data,
          id,
          partner,
          team,
          cpfcnpj: data.cpfcnpj && data.cpfcnpj.replace(/\D/g, ""),
          rg: data.rg && data.rg.replace(/\D/g, ""),
          zipcode: data.zipcode && data.zipcode.replace(/\D/g, ""),

          cpf01: data.cpf01 && data.cpf01.replace(/\D/g, ""),
          rg01: data.rg01 && data.rg01.replace(/\D/g, ""),
          mobile01: data.mobile01 && data.mobile01.replace(/\D/g, ""),

          cpf02: data.cpf02 && data.cpf02.replace(/\D/g, ""),
          rg02: data.rg02 && data.rg02.replace(/\D/g, ""),
          mobile02: data.mobile02 && data.mobile02.replace(/\D/g, ""),

          schoolPhone: data.schoolPhone && data.schoolPhone.replace(/\D/g, ""),
        });

        PopupAlert("Paciente salvo!");
        push("/patient");
      } catch (error) {
        PopupAlert(`Erro ao salvar - ${error.response.data.message}`, "error");
      }
    },
    [id, partner, push, team]
  );

  const handleAddPartner = useCallback(async () => {
    const {
      patientPartner: patientPartnerId,
      patientPartnerName,
      patientPartnerPhone,
      patientPartnerEmail,
    } = getValues();

    if (patientPartnerId === "0" || patientPartnerName.length < 2) {
      PopupAlert("Tipo de Acompanhamento e Nome são obrigatórios", "warning");
      return;
    }
    const { label: patientPartner } = optionsPartnerValue.find((option) => {
      return option.value === patientPartnerId;
    });

    setPartner((state) => [
      ...state,
      {
        patientPartnerId,
        patientPartner,
        patientPartnerName,
        patientPartnerPhone,
        patientPartnerEmail,
      },
    ]);

    setValue("patientPartner", "0");
    setValue("patientPartnerName", "");
    setValue("patientPartnerPhone", "");
    setValue("patientPartnerEmail", "");
  }, [getValues, setValue]);

  const handleAddTeam = useCallback(async () => {
    const { patientTeam: patientTeamId } = getValues();
    const { label: patientTeam } = optionsTeamValue.find((option) => {
      return option.value === patientTeamId;
    });

    const addTeam = { id: patientTeamId, name: patientTeam };
    setTeam([...team, addTeam]);
  }, [getValues, optionsTeamValue, team]);

  function handleRemovePartner(id) {
    const tempPartner = partner.filter((key) => key.patientPartnerId !== id);
    setPartner(tempPartner);
  }
  function handleRemoveTeam(id) {
    const tempTeam = team.filter((key) => key.id !== id);
    setTeam(tempTeam);
  }

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <h2>Pacientes - Cadastro</h2>
        </Title>

        <EntityForm onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="name"
            label="Nome da criança"
            rules={{ required: true, maxLength: 60 }}
            register={register}
            maxLength="60"
          />
          <InputMask
            name="cpfcnpj"
            label="CPF"
            mask="999.999.999-99"
            register={register}
          />
          <Input label="RG" name="rg" register={register} maxLength="11" />
          <Input
            name="birthday"
            label="Nascimento"
            mask="99/99/9999"
            max={format(new Date(), "yyyy-MM-dd")}
            type="date"
            rules={{ required: true }}
            register={register}
          />
          <Input
            name="financialName"
            label="Responsável financeiro"
            rules={{ required: true, maxLength: 100 }}
            register={register}
            maxLength="60"
          />
          <Input
            label="Observação"
            name="note"
            rules={{ required: false }}
            register={register}
            maxLength="254"
          />
          <InputMask
            name="zipcode"
            label="CEP"
            mask="99999-999"
            register={register}
          />
          <InputMask name="uf" label="UF" mask="aa" register={register} />
          <Input
            name="city"
            label="Cidade"
            rules={{ required: false }}
            register={register}
            maxLength="60"
          />
          <Input
            name="neighborhood"
            label="Bairro"
            rules={{ required: false }}
            register={register}
            maxLength="60"
          />
          <Input
            name="address"
            label="Logradouro"
            rules={{ required: false }}
            register={register}
            maxLength="60"
          />
          <Input
            name="addressNumber"
            label="Número"
            rules={{ required: false }}
            register={register}
            maxLength="15"
          />
          <Input
            name="complement"
            label="Complemento"
            rules={{ required: false }}
            register={register}
            maxLength="60"
          />

          <Select
            name="parentage01"
            label="Parentesco"
            options={optionsValue}
            register={register}
          />
          <Input
            name="name01"
            label="Nome 01"
            rules={{ required: true, maxLength: 60 }}
            register={register}
            maxLength="60"
          />
          <InputMask
            name="cpf01"
            label="CPF"
            mask="999.999.999-99"
            register={register}
          />
          <Input
            name="rg01"
            label="RG"
            rules={{ required: false }}
            register={register}
            maxLength="11"
          />

          <InputMask
            name="mobile01"
            label="Celular"
            mask="(99) 99999-9999"
            register={register}
          />
          <Input
            name="email01"
            label="Email"
            rules={{ required: false }}
            register={register}
            maxLength="100"
          />
          <InputMask
            name="birthday01"
            label="Nascimento"
            mask="99/99/9999"
            register={register}
          />

          <Select
            name="parentage02"
            label="Parentesco"
            options={optionsValue}
            register={register}
          />
          <Input
            name="name02"
            label="Nome 02"
            rules={{ maxLength: 60, required: false }}
            register={register}
            maxLength="60"
          />
          <InputMask
            name="cpf02"
            label="CPF"
            mask="999.999.999-99"
            register={register}
          />
          <Input
            name="rg02"
            label="RG"
            rules={{ required: false }}
            register={register}
            maxLength="11"
          />

          <InputMask
            name="mobile02"
            label="Celular"
            mask="(99) 99999-9999"
            register={register}
          />
          <Input
            name="email02"
            label="Email"
            rules={{ required: false }}
            register={register}
            maxLength="100"
          />
          <InputMask
            name="birthday02"
            label="Nascimento"
            mask="99/99/9999"
            register={register}
          />

          <Input
            name="school"
            label="Escola"
            rules={{ required: false }}
            register={register}
            maxLength="60"
          />
          <Input
            name="schoolGrade"
            label="Serie"
            rules={{ required: false }}
            register={register}
            maxLength="15"
          />
          <InputMask
            name="schoolPhone"
            label="Telefone"
            mask="(99) 99999-9999"
            register={register}
          />
          <Input
            name="schoolEmail"
            label="Email"
            rules={{ required: false }}
            register={register}
            maxLength="100"
          />
          <Input
            name="schoolPartner"
            label="Profissional responsável"
            rules={{ required: false }}
            register={register}
            maxLength="60"
          />

          <Select
            name="patientPartner"
            label="Acompanhamento Externo"
            options={optionsPartnerValue}
            register={register}
          />
          <Input
            name="patientPartnerName"
            label="Nome"
            rules={{ required: false }}
            register={register}
            maxLength="60"
          />
          <InputMask
            name="patientPartnerPhone"
            label="Telefone"
            mask="(99) 99999-9999"
            register={register}
          />
          <Input
            name="patientPartnerEmail"
            label="Email"
            rules={{ required: false }}
            register={register}
            maxLength="100"
          />

          <div style={{ gridArea: "addPartner", marginTop: "auto" }}>
            <Button
              type="button"
              onClick={handleAddPartner}
              style={{ padding: "13px" }}
            >
              <FiPlus size={24} />
            </Button>
          </div>

          <PartnersTable>
            <thead>
              <tr>
                <th>Acompanhamento Externo</th>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Email</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {partner.map((key, index) => (
                <PartnersRow key={index}>
                  <td>{key.patientPartner}</td>
                  <td>{key.patientPartnerName}</td>
                  <td>{key.patientPartnerPhone}</td>
                  <td>{key.patientPartnerEmail}</td>
                  <td>
                    <Button
                      gridArea="cancel"
                      type="button"
                      onClick={() => handleRemovePartner(key.patientPartnerId)}
                      style={{ padding: "13px" }}
                    >
                      <FiMinus size={24} />
                    </Button>
                  </td>
                </PartnersRow>
              ))}
            </tbody>
          </PartnersTable>

          <Select
            name="patientTeam"
            label="Equipe Intervenção"
            options={optionsTeamValue}
            register={register}
          />

          <div style={{ gridArea: "addTeam", marginTop: "auto" }}>
            <Button
              type="button"
              onClick={handleAddTeam}
              style={{ padding: "13px" }}
            >
              <FiPlus size={24} />
            </Button>
          </div>

          <TeamsTable>
            <thead>
              <tr>
                <th>Equipe Intervenção</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {team.map((key, index) => (
                <TeamsRow key={index}>
                  <td>{key.name}</td>
                  <td>
                    <Button
                      gridArea="cancel"
                      type="button"
                      onClick={() => handleRemoveTeam(key.id)}
                      style={{ padding: "13px" }}
                    >
                      <FiMinus size={24} />
                    </Button>
                  </td>
                </TeamsRow>
              ))}
            </tbody>
          </TeamsTable>

          <Button gridArea="cancel" type="button" onClick={goBack}>
            Voltar
          </Button>
          <Button gridArea="save">Salvar</Button>
        </EntityForm>
      </Main>
    </>
  );
}
