import React, { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { api } from "../../services/api";
import { PopupAlert } from "../../utils/PopupAlert";

import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import { Input } from "../../components/Input";
import { Button } from "../../components/NewButton";
import { Main } from "./styles";
import { AuthContext } from "../../contexts/AuthContext";

export default function Password() {
  const { handleSubmit, register } = useForm();
  const { user } = useContext(AuthContext);

  const fullName = user?.fullname;

  const handleOnSubmit = useCallback(
    async (data) => {
      if (data.newPassword !== data.newPasswordAgain) {
        PopupAlert("Senhas digitadas não são iguais", "error");
        return false;
      }
      if (data.newPassword.length < 5) {
        PopupAlert("Senha precisa ter mais que 5 caracteres", "error");
        return false;
      }

      const administrator_id = user?.administrator_id;
      const user_id = user?.id;

      const dataToSave = { ...data, administrator_id, user_id };

      try {
        await api.post(`/users/password`, dataToSave);

        PopupAlert("Senha alterada com sucesso");
      } catch (err) {
        PopupAlert("Erro para alterar senha", "error");
        console.error(err.response.data);
      }
    },
    [user?.administrator_id, user?.id]
  );

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          {fullName}
          <Input
            name="oldPassword"
            placeholder="Senha Atual"
            type="password"
            autoComplete="off"
            autoFocus
            required
            register={register}
          />
          <Input
            name="newPassword"
            placeholder="Nova senha"
            type="password"
            autoComplete="off"
            required
            register={register}
          />
          <Input
            name="newPasswordAgain"
            placeholder="Nova senha"
            type="password"
            autoComplete="off"
            required
            register={register}
          />
          <Button>Salvar</Button>
        </form>
      </Main>
    </>
  );
}
