import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { api } from "../../../services/api";
import { Menu } from "../../../components/Menu";
import { Header } from "../../../components/Header";
import { Button } from "../../../components/NewButton";

import { useEducationalPlanStore } from "../../../store/educationalPlanStore/educationalPlanStore"

import {
  ButtonContainer,
  Main,
  Title,
  Description,
  Question,
  QuestionContainer,
  QuestionTitle,
  InputGroup,
  PatientDetail,
  CompetenceTitle,
  IntervencaoBox
} from "./styles";
import { PopupAlert, PopupConfirm } from "../../../utils/PopupAlert";
import formatter from "../../../utils/formatter";
import { Link } from "react-router-dom";
import {
  FiBarChart,
  FiFileText,
  FiGrid,
  FiList,
  FiMinus,
  FiPlus,
} from "react-icons/fi";
import { Select } from "../../../components/Select";

export default function TestForm() {
  const { patientId, id } = useParams();
  const { register, handleSubmit, setValue } = useForm();
  const { push } = useHistory();

  const [patient, setPatient] = useState();
  const [competences, setCompetences] = useState([]);
  const [selectedCompetenceId, setSelectedCompetenceId] = useState(1);
  const [isFinished, setIsFinished] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [usedDomain, setUsedDomain] = useState([]);

  useEffect(() => {
    api.post(`/patient/findid`, { id: patientId }).then((response) => {
      let years_old = "SEM IDADE";
      if (response.data.birthday !== null) {
        years_old = formatter.date.yearsOldWithMonth(response.data.birthday);
      }
      setPatient({ ...response.data, years_old });
    });

    api.get(`/denverAbility`).then((response) => {
      setCompetences(response.data);
      setUsedDomain(response.data);
    });

    api
      .get("/testAnswer", { params: { test_id: id, patientId } })
      .then((response) => {
        setAnswers(response.data);

        response.data.forEach((answer) => {
          setValue(String(answer.denverAbility_id), String(answer.answer));
        });

        response.data.forEach((answer) => {
          setValue(String(answer.id + "inter"), String(answer.intervention));
        });
      });
    api.get(`/test/${id}`).then((response) => {
      setIsFinished(response.data.finished);
    });
  }, [id, patientId, setValue]);

  useEffect(() => {
    let index = 0;
    let selectTemp = [];

    answers.forEach((answer) => {
      if (index !== answer.denverCompetence_id) {
        index = answer.denverCompetence_id;
        selectTemp.push(
          ...usedDomain.filter(
            (competence) => competence.id === answer.denverCompetence_id
          )
        );
      }
    });
    setSelectedDomain(selectTemp);
  }, [usedDomain, answers]);

  async function handleFinishTest() {
    try {
      const confirmation = await PopupConfirm({
        title: "Finalizar teste",
        message: "Deseja realmente finalizar esse teste teste?",
        successMessage: "Sim",
        cancelMessage: "Não",
      });

      if (confirmation) {
        await api.put(`/test/${id}/finish`);

        setIsFinished(true);
        PopupAlert("Teste finalizado");
      }
    } catch {
      PopupAlert("Erro ao finalizar teste, tente novamente.", "error");
    }
  }

  const handleSubmitTest = handleSubmit(async (formData) => {
    const data = {
      test_id: Number(id),
      questions: [],
    };

    Object.keys(formData).forEach((question_id) => {
      const answer = Number(formData[question_id]);

      if (answer !== 0) {
        data.questions.push({
          question_id: Number(question_id),
          answer,
        });
      }
    });

    try {
      await api.post("/testAnswer", data);

      PopupAlert("Respostas salvas com sucesso!");
      push(`/patient/${patientId}/test`);
    } catch {
      PopupAlert("Erro ao salvar respostas, tente novamente!", "error");
    }
  });

  const filteredDomain = useMemo(() => {
    return competences.filter(
      (competence) => competence.level === selectedLevel
    );
  }, [competences, selectedLevel]);

  function handleAdd(id) {
    //Find - If already added ignore
    const double = selectedDomain.find((competence) => competence.id === id);
    if (!double) {
      const selectedTemp = competences.filter(
        (competence) => competence.id === id
      );
      let selectedAndNew = [...selectedDomain, ...selectedTemp];
      setSelectedDomain(selectedAndNew);
    }
  }

  async function handleRemove(competence) {
    const confirmation = await PopupConfirm({
      title: "Excluir Competência?",
      message: "As respostas que não foram salvas serão perdidas, continuar?",
      successMessage: "Sim",
      cancelMessage: "Não",
    });

    if (confirmation) {
      const denverAbility_id = [];
      competence.abilities.forEach((element) => {
        denverAbility_id.push(element.id);
      });
      await api.delete("/testAnswer", {
        params: { test_id: id, denverAbility_id },
      });

      const tempDomain = selectedDomain.filter(
        (domains) => domains.id !== competence.id
      );
      setSelectedDomain(tempDomain);
      push(`/patient/${patientId}/test`);
    }
  }

  const setStoreSelectedDomains = useEducationalPlanStore((state) => state.setStoreSelectedDomains)
  const setStoreInterventionSelected = useEducationalPlanStore((state) => state.setStoreInterventionSelected)

  const handleCreateIntervention = handleSubmit(async (data) => {
    try {
      const interventionsSelected = [];
      Object.keys(data).forEach((key) => {
        if (key.substring(0, 5) === "inter" && data[key]) {
          interventionsSelected.push(data[key]);
        }
      });

      setStoreSelectedDomains(selectedDomain)
      setStoreInterventionSelected(interventionsSelected)

      // const result = await api.post("/report/intervencao", {
      //   patient_id: patientId,
      //   test_id: id,
      //   interventionsSelected,
      // });
      //window.open(result.data);
    } catch (error) {
      console.log(error);
      PopupAlert(`Erro ao gerar!`, "error");
    }
  });

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <h2>
            Checklist Currículo Modelo de Intervenção Precoce
            {isFinished && " Finalizado (Somente leitura)"}
          </h2>

          {isFinished && (
            <div>
              <Link
                to={{
                  pathname: `/denver/graph/acquired/${id}`,
                  query: { name: patient?.name, years_old: patient?.years_old },
                }}
              >
                <FiBarChart size={18} />
                Gráfico
              </Link>

              <Link
                to={{
                  pathname: `/denver/graph/acquired-table/${id}`,
                  query: { name: patient?.name, years_old: patient?.years_old },
                }}
              >
                <FiGrid size={18} />
                Tabela
              </Link>

              <Link
                to={{
                  pathname: `/patient/report`,
                  query: {
                    patient_id: patient?.id,
                    name: patient?.name,
                    years_old: patient?.years_old,
                    test_id: id,
                  },
                }}
              >
                <FiFileText size={18} />
                Relatório
              </Link>
              {/* <Link
                to={{
                  pathname: `/patient/${patientId}/test/${id}`,
                  query: {
                    patient_id: patient?.id,
                    name: patient?.name,
                    years_old: patient?.years_old,
                    test_id: id,
                  },
                }}
                onClick={() => handleCreateIntervention()}
              >
                <FiList size={18} />
                Intervenção
              </Link> */}
              {/* <Link
                to={{
                  pathname: `/patient/${patientId}/test/${id}/educationalPlan/${id}`,
                  query: {
                    patient_id: patient?.id,
                    name: patient?.name,
                    years_old: patient?.years_old,
                    test_id: id,
                  },
                }}
                onClick={() => handleCreateIntervention()}
              >
                <FiBook size={18} />
                Plano de ensino
              </Link> */}
            </div>
          )}
        </Title>

        <PatientDetail>
          <p>
            <strong>Nome: </strong>
            <Link
              to={`/patient/add/${patient?.id}`}
              style={{ textDecoration: "none", color: "#353535" }}
            >
              {patient?.name}
            </Link>
          </p>
          <p>
            <strong>Idade:</strong>
            <Link
              to={`/patient/add/${patient?.id}`}
              style={{ textDecoration: "none", color: "#353535" }}
            >
              {" "}
              {patient?.years_old}
            </Link>
          </p>
        </PatientDetail>

        {!isFinished && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr auto",
              alignItems: "end",
              gap: "1rem",
              marginTop: "2rem",
            }}
          >
            <Select
              label="Nível"
              onChange={(event) => setSelectedLevel(Number(event.target.value))}
              options={[
                {
                  value: 1,
                  label: 1,
                },
                {
                  value: 2,
                  label: 2,
                },
                {
                  value: 3,
                  label: 3,
                },
                {
                  value: 4,
                  label: 4,
                },
              ]}
            />
            <Select
              label="Competência"
              onChange={(event) => {
                const index = Number(event.target.value);
                setSelectedCompetenceId(index);
              }}
              options={filteredDomain.map((competence, index) => ({
                value: competence.id,
                label: `${competence.title} (Nível ${selectedLevel})`,
              }))}
            />

            <Button onClick={() => handleAdd(selectedCompetenceId)}>
              <FiPlus size={18} />
            </Button>
          </div>
        )}

        <form onSubmit={handleSubmitTest}>
          {selectedDomain.map((competence) => (
            <React.Fragment key={competence.id}>
              <CompetenceTitle>
                {competence.title} - Nível {competence.level}{" "}
                {!isFinished && (
                  <button
                    type="button"
                    onClick={() => handleRemove(competence)}
                    style={{
                      borderColor: "none",
                      color: "red",
                      height: "23px",
                      width: "25px",
                      marginBottom: "5px",
                      paddingTop: "2px",
                    }}
                  >
                    <FiMinus />
                  </button>
                )}
                <span style={{ float: "right", fontSize: "12px" }}>
                  Intervenção
                </span>
              </CompetenceTitle>

              {competence.abilities.map((ability, index) => (
                <QuestionContainer key={index}>
                  <Question>
                    <QuestionTitle>
                      {index + 1} - {ability.name}
                    </QuestionTitle>

                    <Description>{ability.description}</Description>
                  </Question>

                  <InputGroup>
                    <label htmlFor={String(ability.id) + "-3"}>
                      <input
                        {...register(String(ability.id))}
                        type="radio"
                        id={ability.id + "-3"}
                        value="3"
                        readOnly={isFinished}
                      />
                      Adquirido
                    </label>

                    <label htmlFor={String(ability.id) + "-2"}>
                      <input
                        {...register(String(ability.id))}
                        type="radio"
                        id={ability.id + "-2"}
                        value="2"
                        readOnly={isFinished}
                      />
                      Parcialmente
                    </label>

                    <label htmlFor={String(ability.id) + "-1"}>
                      <input
                        {...register(String(ability.id))}
                        type="radio"
                        id={ability.id + "-1"}
                        value="1"
                        readOnly={isFinished}
                      />
                      Não adquirido
                    </label>

                    <label htmlFor={String(ability.id) + "-0"}>
                      <input
                        {...register(String(ability.id))}
                        type="radio"
                        id={ability.id + "-0"}
                        defaultChecked
                        value="0"
                        readOnly={isFinished}
                      />
                      Sem oportunidade
                    </label>
                  </InputGroup>
                  {isFinished && (
                    <div style={{ padding: "0 25px" }}>
                      <input
                        {...register(String("inter" + ability.id))}
                        type="checkbox"
                        id={"inter" + ability.id}
                        value={ability.id}
                      />
                    </div>
                  )}
                </QuestionContainer>
              ))}
            </React.Fragment>
          ))}

          <IntervencaoBox>
            {isFinished && (
              <>
                <Link
                  to={{
                    pathname: `/patient/${patientId}/test/${id}/educationalPlan`,
                    query: {
                      patient_id: patient?.id,
                      name: patient?.name,
                      years_old: patient?.years_old,
                      test_id: id,
                    },
                  }}
                  onClick={() => handleCreateIntervention()}
                >
                  <FiList size={18} />
                  Intervenção
                </Link>
              </>
            )}
          </IntervencaoBox>

          {!isFinished && (
            <ButtonContainer>
              <Button
                type="button"
                gridArea="cancel"
                onClick={handleFinishTest}
              >
                Finalizar
              </Button>

              <Button type="submit">Salvar</Button>
            </ButtonContainer>
          )}
        </form>
      </Main>
    </>
  );
}
