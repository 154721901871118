import { shade } from "polished";
import styled from "styled-components";

export const Main = styled.main`
  max-width: 1300px;
  margin: 30px auto 0;
  padding: 0 0 24px;
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 48px;
    text-align: center;
    width: 780px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    font-size: 36px;
  }

  > div {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.button.background};
    border-radius: 10px;
    padding: 8px;
    margin-left: 8px;
    transition: background-color 0.2s;
    text-decoration: none;
    font-weight: bold;

    span {
      margin-left: 8px;
    }

    svg {
      margin-right: 0.5rem;
    }

    &:hover {
      background: ${(props) => shade(0.2, props.theme.primary)};
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  gap: 1rem;

  padding-left: 2rem;

  input {
    margin-right: 0.1rem;
  }
  label {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.5rem;
`;

export const SubmetaContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


export const InputTitle = styled.input`
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 16px;
`;

export const InputAbility = styled.input`
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 12px;
`;

export const InputDescription = styled.input`
  background-color: transparent;
  border: none;
  font-size: 12px;
`;