import React, { useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router";
import { Chart } from "react-google-charts";
import { api } from "../../../services/api";
import { Menu } from "../../../components/Menu";
import { Header } from "../../../components/Header";
import { Main, PatientDetail } from "../styles";

export default function Acquired() {
  const { id } = useParams();
  const { query } = useLocation();
  const [data, setData] = useState([]);
  const [showPrevious, setShowPrevious] = useState(false);
  const [showPartial, setShowPartial] = useState();

  useEffect(() => {
    api
      .get(`/test/graph/acquired`, { params: { id } })
      .then((response) => setData(response.data));
  }, [id]);

  const graphData = useMemo(() => {
    let arrayData = [[]];
    let col = ["Domínio", "Esperado"];

    !showPartial && col.push("Adquirido");
    showPartial === "show" && col.push("Adquirido", "Adquirido Parcial");
    showPartial === "sum" && col.push("Adquirido e Parcial");
    if (showPrevious) {
      !showPartial && col.push("Adquirido Anterior");
      showPartial === "show" &&
        col.push("Adquirido Anterior", "Adquirido Parcial Anterior");
      showPrevious &&
        showPartial === "sum" &&
        col.push("Adquirido e Parcial Anterior");
    }
    arrayData = [col];

    data.forEach((keys) => {
      let row = [];
      row.push(keys.domain, Number(keys.total));

      !showPartial && row.push(Number(keys.answers));
      showPartial === "show" &&
        row.push(Number(keys.answers), Number(keys.partial));
      showPartial === "sum" &&
        row.push(Number(keys.answers) + Number(keys.partial));
      if (showPrevious) {
        !showPartial && row.push(Number(keys.previous));
        showPartial === "show" &&
          row.push(Number(keys.previous), Number(keys.previousPartial));
        showPartial === "sum" &&
          row.push(Number(keys.previous) + Number(keys.previousPartial));
      }
      arrayData.push(row);
    });
    return arrayData;
  }, [data, showPartial, showPrevious]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <PatientDetail>
          <p>
            <strong>Nome: </strong>
            {query?.name}
          </p>
          <p>
            <strong>Idade: </strong>
            {query?.years_old}
          </p>
          <label htmlFor="previous">
            <input
              type="checkbox"
              onClick={(event) => setShowPrevious(event.target.checked)}
            />
            Mostrar teste anterior |{" "}
          </label>
          Parcialmente adquiridos:{" "}
          <label htmlFor="partial">
            <input
              type="radio"
              name="partial"
              onClick={() => setShowPartial(false)}
            />
            Não mostar{" "}
          </label>
          <label htmlFor="partial">
            <input
              type="radio"
              name="partial"
              onClick={() => setShowPartial("show")}
            />
            Mostar{" "}
          </label>
          <label htmlFor="partial">
            <input
              type="radio"
              name="partial"
              onClick={() => setShowPartial("sum")}
            />
            Somar
          </label>
        </PatientDetail>
        <div className="dashboard-graph">
          <Chart
            width={"1200px"}
            height={"1200px"}
            chartType="BarChart"
            loader={<div>Carregando...</div>}
            data={graphData}
            options={{
              hAxis: { gridlines: { count: 2 } },
              title: "Desempenho Checklist Currículo Denver",
              titleTextStyle: { fontSize: 20, bold: true, color: "#1F4E79" },
              is3D: true,
              chartArea: { width: "45%", height: "85%" },
              colors: ["#ecb7bf", "#a8b4ff", "#cc2b5e", "#06beb6", "#de6262"],
              annotations: {
                textStyle: {
                  fontSize: 10,
                },
              },
              vAxes: {
                0: {
                  title:
                    "Nível 1: 1a-1a 6meses / Nível 2: 1a6meses-2a / Nível 3: 2a-3a / Nível 4: 3a-4a\n\n",
                },
              },
            }}
          />
        </div>
      </Main>
    </>
  );
}
