import React, { useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router";
import { api } from "../../../services/api";
import { Chart } from "react-google-charts";

import { Menu } from "../../../components/Menu";
import { Header } from "../../../components/Header";
import { Main, PatientDetail, Title } from "../styles";

export default function AcquiredTable() {
  const { id } = useParams();
  const { query } = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    api
      .get(`/test/graph/level`, { params: { id } })
      .then((response) => setData(response.data));
  }, [id]);

  const arrayData = useMemo(() => {
    const arrayData = [
      [
        "Domínio",
        "Nível 1 (1a - 1a 6 meses)",
        "Nível 2 (1a 6 meses - 2a)",
        "Nível 3 (2a - 3a)",
        "Nível 4 (3a - 4a)",
      ],
    ];

    data.forEach((keys) => {
      if (keys.level1 === 1 || keys.level1 === 2) {
      }

      const level1 = keys.level1 > 0 ? "✓" : "O";
      const level2 = keys.level2 > 0 ? "✓" : "O";
      const level3 = keys.level3 > 0 ? "✓" : "O";
      const level4 = keys.level4 > 0 ? "✓" : "O";
      arrayData.push([keys.domain, level1, level2, level3, level4]);
    });

    return arrayData;
  }, [data]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <span>
            <h2>Resultado</h2>
          </span>
        </Title>
        <PatientDetail>
          <p>
            <strong>Nome: </strong>
            {query?.name}
          </p>
          <p>
            <strong>Idade: </strong>
            {query?.years_old}
          </p>
        </PatientDetail>
        <div style={{ margin: "15px" }}>
          ✓ Adquirido | O Não avaliado
          <Chart
            width={"100%"}
            height={"400px"}
            chartType="Table"
            loader={<div>Carregando...</div>}
            data={arrayData}
            options={{
              title: "Competências Desenvolvidas",
              legend: { position: "none" },
              annotations: {
                textStyle: {
                  fontSize: 18,
                  bold: true,
                  textAlign: "center",
                },
              },
            }}
          />
        </div>
      </Main>
    </>
  );
}
