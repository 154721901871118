import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  span {
    background: ${props => props.theme.primary};
    color: ${props => props.theme.text.inPrimary};
    padding: 8px  16px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 800;
    opacity: 0;
    transition: 0.4s;
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    &::before {
      position: absolute;
      content: '';
      border-style: solid;
      border-color: ${props => props.theme.primary} transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`
