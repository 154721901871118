import styled from "styled-components";

export const Container = styled.div`
  background: ${props => props.theme.menu.background};

  display: flex;
  justify-content: center;

  > nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
