import styled from "styled-components";

export const Main = styled.main`
  max-width: 1200px;
  margin: 24px auto 0;
  display: flex;

  h2 {
    font-size: 36px;
  }
`;

export const Lasts = styled.div`
  margin-top: 8px;
  max-width: 650px;

  h3 {
    position: relative;
    padding: 0 8px 8px;
    margin-bottom: 16px;
    font-weight: 500;

    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      color: ${(props) => props.theme.yellow};
    }

    &::after {
      content: "";
      background: ${(props) => props.theme.shape.default};
      bottom: 0;
      width: 98%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      margin: 0 auto;
      height: 2px;
    }
  }

  li {
    margin-top: 8px;
    position: relative;
    width: 100%;
    list-style: none;
    padding: 0 12px 12px;

    strong {
      display: block;
      font-size: 14px;
      font-weight: 500;
      font-style: italic;
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
    }

    &::after {
      content: "";
      background: #333;
      bottom: 0;
      width: 95%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      margin: 0 auto;
      height: 1px;
    }
  }
`;
