import styled, { css } from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  grid-area: ${props => props.gridPosition || '-'};
  cursor: text;
  flex: 1;

  label {
    color: ${props => props.theme.text.label};
    font-weight: bold;
    margin-bottom: 6px;
    display: block;
  }

  > div {
    border-radius: 10px;
    padding: 12px 8px;
    background: ${props => props.theme.background.input};
    border: 2px solid ${props => props.theme.text.label};
    max-width: 100%;

    input {
      background: transparent;
      border: none;
      width: 100%;
      outline: none;

      ::placeholder {
        color: ${props => props.theme.text.label};
      }

      &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
        background: transparent;
      }

      ${props => props.type === 'number' && css`
        text-align: right;
      `}
    }
  }

  :focus-within {
    > div {
      border: 2px solid ${props => props.theme.primary};
    }

    label {
      color: ${props => props.theme.primary};
    }
  }

  & + div {
    margin-top: 8px;
  }
`
