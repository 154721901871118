import React from "react";

import { Can } from "../Can";
import MenuItem from "./MenuItem";

import { Container } from "./styles";

export function Menu() {
  return (
    <Container>
      <nav>
        <MenuItem href="/main" label="Home" />

        <Can roles={["manager", "admin"]}>
          <MenuItem href="/patient" label="Pacientes" />
        </Can>

        <Can roles={["manager", "technical"]}>
          <MenuItem
            label="Instrumentos"
            subMenu={[{ label: "Denver", href: "/denver" }]}
          />
        </Can>

        <Can roles={["manager", "admin"]}>
          <MenuItem href="/report" label="Relatórios" />
        </Can>

        <MenuItem
          label="Cadastros"
          subMenu={[
            {
              label: "Pacientes",
              href: "/patient",
              permissions: ["manager", "admin", "lawyer", "technical"],
            },
            {
              label: "Profissionais",
              href: "/expert",
              permissions: ["manager", "admin", "lawyer"],
            },
          ]}
        />
      </nav>
    </Container>
  );
}
