import React from "react";
import Loader from "react-loader-spinner";

import { Container } from "./styles";

export function Button({
  children,
  gridArea = "-",
  isLoading = false,
  type = "submit",
  ...rest
}) {
  return (
    <Container
      {...rest}
      type={type}
      gridArea={gridArea}
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader type="TailSpin" color="#FFFFFF" height={16} width={16} />
      ) : (
        children
      )}
    </Container>
  );
}
