import React, { useCallback, useRef } from "react";

import ReactInputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import { Container } from "./styles";

export function InputMask({
  name,
  label,
  mask,
  type = "text",
  inputProps,
  register,
  rules,
  ref,
  field,
  ...rest
}) {
  const containerRef = useRef(null);
  const { control } = useForm();

  const handleClick = useCallback(() => {
    containerRef.current.firstChild.focus();
  }, []);

  return (
    <Container gridPosition={name}>
      <label htmlFor={name}>{label}</label>

      <div onClick={handleClick} ref={containerRef}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <ReactInputMask
              mask={mask}
              name={name}
              id={name}
              {...field}
              {...register(name, rules)}
              {...rest}
            />
          )}
        />
      </div>
    </Container>
  );
}
