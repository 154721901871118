import { api } from "../services/api";
import { PopupAlert } from "./PopupAlert";

export const getCNPJAPI = async (cnpj) => {
  let result = {};

  await api
    .get(
      `https://cors-anywhere.herokuapp.com/https://www.receitaws.com.br/v1/cnpj/${cnpj}`
    )
    .then((response) => {
      if (response.status === 200) {
        const data = response.data;

        const tradeName = data.nome_fantasia
          ? data.nome_fantasia
          : data.razao_social;
        const capital = Number(data.capital_social).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
        });

        result = {
          cnpj: data.cnpj,
          legalName: data.nome,
          tradeName: tradeName,
          zipcode: data.cep ? data.cep.replace(/\D/g, "") : null,
          uf: data.uf,
          city: data.municipio,
          neighborhood: data.bairro,
          address: data.logradouro,
          addressNumber: data.numero,
          complement: data.complemento,
          capital,
          sector: data.atividade_principal[0].text,
          founding_date: data.abertura,
          phone: data.telefone,
          email: data.email,
          status: data.situacao,
          statusReason: data.motivo_situacao,
        };
        return result;
      }
    })
    .catch((error) => {
      console.error(error.response.status);
      if (error.response.status === 403) {
        result = api
          .get(`https://minhareceita.org/${cnpj}`)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;

              const tradeName = data.nome_fantasia
                ? data.nome_fantasia
                : data.razao_social;
              const capital = Number(data.capital_social).toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                }
              );

              result = {
                cnpj: data.cnpj,
                legalName: data.razao_social,
                tradeName: tradeName,
                zipcode: data.cep,
                uf: data.uf,
                city: data.municipio,
                neighborhood: data.bairro,
                address: data.descricao_tipo_logradouro + " " + data.logradouro,
                addressNumber: data.numero,
                complement: data.complemento,
                capital,
                sector: data.cnae_fiscal_descricao,
                founding_date: data.data_inicio_atividade,
                phone: data.ddd_telefone_1,
                email: "",
              };
              return result;
            }
          });
      } else {
        console.error(error);
      }
    });

  return result;
};

export const cpfCnpjAddMask = (cpfCNPJ) => {
  //Return CPF or CNPJ with Mask
  if (!cpfCNPJ) {
    return cpfCNPJ;
  }

  cpfCNPJ = cpfCNPJ.replace(/\D/g, ""); //Remove all characters

  if (cpfCNPJ.length === 13) {
    cpfCNPJ = "0" + cpfCNPJ;
  }

  if (cpfCNPJ.length === 14) {
    cpfCNPJ = cpfCNPJ.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }

  if (cpfCNPJ.length <= 11) {
    //CPF
    cpfCNPJ = cpfCNPJ.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return cpfCNPJ;
};

export const cepAddMask = (cep) => {
  if (!cep) {
    return cep;
  }
  cep = cep.replace(/\D/g, ""); //Remove all characters
  if (cep.length !== 8) {
    return cep;
  }
  return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
};

export const phoneNumberAddMask = (phoneNumberString) => {
  if (!phoneNumberString) {
    return "-";
  }
  phoneNumberString = phoneNumberString.replace(/\D/g, ""); //Remove all characters
  if (phoneNumberString.length === 8) {
    //return phoneNumberString;
    return phoneNumberString.replace(/(\d{4})(\d{4})/g, "$1-$2");
  }
  if (phoneNumberString.length === 10) {
    //return phoneNumberString;
    return phoneNumberString.replace(/(\d{2})(\d{4})(\d{4})/g, "($1)$2-$3");
  }
  if (phoneNumberString.length === 11) {
    //return phoneNumberString;
    return phoneNumberString.replace(/(\d{2})(\d{4})(\d{5})/g, "($1)$2-$3");
  }

  return phoneNumberString;
};

export const isCNPJ = (cnpj) => {
  cnpj = cnpj.trim();
  cnpj = cnpj.replace(/[^\d]+/g, "");
  cnpj = zeroPad(cnpj, 14); //Zero Left

  var v1 = 0;
  var v2 = 0;
  var aux = false;
  var i,
    p1,
    p2 = 0;

  for (i = 1; cnpj.length > i; i++) {
    if (cnpj[i - 1] !== cnpj[i]) {
      aux = true;
    }
  }

  if (aux === false) {
    return false;
  }

  for (i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
    if (p1 >= 2) {
      v1 += cnpj[i] * p1;
    } else {
      v1 += cnpj[i] * p2;
    }
  }

  v1 = v1 % 11;

  if (v1 < 2) {
    v1 = 0;
  } else {
    v1 = 11 - v1;
  }

  if (v1 !== Number(cnpj[12])) {
    return false;
  }

  for (i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
    if (p1 >= 2) {
      v2 += cnpj[i] * p1;
    } else {
      v2 += cnpj[i] * p2;
    }
  }

  v2 = v2 % 11;

  if (v2 < 2) {
    v2 = 0;
  } else {
    v2 = 11 - v2;
  }

  if (v2 !== Number(cnpj[13])) {
    return false;
  } else {
    return true;
  }
};

export const isCPF = (strCPF) => {
  var Soma;
  var Resto;
  Soma = 0;

  strCPF = zeroPad(strCPF, 11); //Zero Left
  //strCPF = strCPF.padStart(11, "0"); //Zero Left

  if (strCPF === "00000000000") return false;

  for (var i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export const zeroPad = (num, places) => {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

export const dateFromMySQL = (mysqlDate) => {
  if (!(typeof mysqlDate === "string")) {
    return;
  }

  const t = mysqlDate.split(/[- : T]/);

  return t[3] && t[4]
    ? `${t[2]}/${t[1]}/${t[0]} - ${t[3]}:${t[4]}`
    : `${t[2]}/${t[1]}/${t[0]}`;
};

export const sendMails = async (classNumber, classId) => {
  await api.post(`/mailer`, {
    company_id: localStorage.getItem("@socialmentes/company_id"),
    classNumber,
    classId,
  });

  PopupAlert(`Email gerado e enviado para fila`);
};

export const pdfCreator = async (classNumber, classId, options) => {
  const { administrator_id, company_id } = options;

  await api.post(`/pdfcreator`, {
    administrator_id,
    company_id,
    classNumber,
    classId,
  });
};

export const currency2BR = (valueUS) => {
  if (valueUS === 0) {
    return "R$ 0,00";
  }

  if (valueUS === undefined) {
    return "";
  }
  if (valueUS === null) {
    return "";
  }

  return !Number(valueUS)
    ? ""
    : new Intl.NumberFormat("pt-br", {
        currency: "BRL",
        style: "currency",
      }).format(valueUS);
};

export const getZipCodeInfo = async (cep) => {
  const response = await api
    .get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error(error));

  return response;
};

export function downloadBlob(blob, name = "file.txt") {
  if (window.navigator && window.navigator.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob);

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = data;
  link.download = name;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}

export const capitalizeWords = (str) => {
  let pieces = str.toLowerCase().split(" ");
  for (let i = 0; i < pieces.length; i++) {
    let j = pieces[i].charAt(0).toUpperCase();
    pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join(" ");
};

export const classDescription = (className) => {
  // IF > 5 = classI or classIV... ELSE < 5 = I or II or III or IV...
  const classNumber = className.length > 5 ? className.substr(5) : className;
  switch (classNumber) {
    case "I":
      return "Classe I - Trabalhista";

    case "II":
      return "Classe II - Garantia Real";

    case "III":
      return "Classe III - Quirografários";

    case "IV":
      return "Classe IV - ME e EPP";

    default:
      return `Valor Inválido: ${className} (function classDescription)`;
  }
};
