import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FiEdit2, FiPlus } from "react-icons/fi";

import { api } from "../../services/api";
import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import { cpfCnpjAddMask, phoneNumberAddMask } from "../../utils";

import { Main, Title, Entity, IconButton } from "./styles";

export default function Patient() {
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    api.get(`/expert`).then((response) => {
      setEntities(response.data);
    });
  }, []);

  const statistics = useMemo(() => {
    if (entities.length > 0) {
      return <p>{entities.length} Pacientes cadastrados</p>;
    } else {
      return "Nenhum profissional cadastrado";
    }
  }, [entities.length]);

  const formattedEntities = useMemo(() => {
    return entities.map((entity) => ({
      ...entity,
      cpfcnpj: cpfCnpjAddMask(entity.cpfcnpj),
      cpfMobileSeparator: entity.mobile && " | ",
      mobileEmailSeparator: entity.mobile && entity.email && " | ",
      mobile: entity.mobile ? phoneNumberAddMask(entity.mobile) : null,
    }));
  }, [entities]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <span>
            <h2>Profissionais</h2>
            {statistics}
          </span>

          <div>
            <Link to="/expert/add">
              <FiPlus size={24} />
            </Link>
          </div>
        </Title>

        {formattedEntities.map((entity) => (
          <Entity key={entity.id}>
            <div>
              <strong>{entity.name}</strong>
              {" | "}
              {entity.cpfcnpj} {entity.cpfMobileSeparator}
              {entity.mobile} {entity.mobileEmailSeparator} {entity.email}
            </div>

            <div>
              <IconButton title="Editar">
                <Link to={`/expert/add/${entity.id}`}>
                  <FiEdit2 size={18} />
                </Link>
              </IconButton>
            </div>
          </Entity>
        ))}
      </Main>
    </>
  );
}
