import React from "react";
import { Switch } from "react-router-dom";
import { Route } from "./components/Route";

import Denver from "./pages/denver";
import DenverCompetence from "./pages/denver/competence";
import DenverGraphAcquired from "./pages/denver/graph/acquired";
import DenverGraphAcquiredTable from "./pages/denver/graph/acquired-table";
import Expert from "./pages/expert";
import ExpertAdd from "./pages/expert/expertadd";
import Login from "./pages/login";
import Main from "./pages/main";
import Password from "./pages/login/password";
import Patient from "./pages/patient";
import PatientAdd from "./pages/patient/patientadd";
import Test from "./pages/patient/test";
import PatientGoal from "./pages/patient/goal";
import PatientReport from "./pages/patient/report";
import TestForm from "./pages/patient/test/form";
import Report from "./pages/report";
import EducationalPlan from "./pages/educationalPlan";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/denver" component={Denver} />
      <Route
        exact
        path="/denver/graph/acquired/:id?"
        component={DenverGraphAcquired}
      />
      <Route
        exact
        path="/denver/graph/acquired-table/:id?"
        component={DenverGraphAcquiredTable}
      />
      <Route
        exact
        path="/denver/competence/:id?"
        component={DenverCompetence}
      />
      <Route exact path="/expert" component={Expert} />
      <Route exact path="/expert/add/:id?" component={ExpertAdd} />
      <Route exact path="/" component={Login} />
      <Route path="/main" component={Main} />
      <Route path="/login/password" component={Password} />
      <Route exact path="/patient" component={Patient} />
      <Route exact path="/patient/:patientId/test" component={Test} />
      <Route exact path="/patient/:patientId/test/:id" component={TestForm} />
      <Route exact path="/patient/:patientId/test/:id/educationalPlan" component={EducationalPlan} />
      <Route exact path="/patient/add/:id?" component={PatientAdd} />
      <Route exact path="/patient/goal/:id?" component={PatientGoal} />
      <Route exact path="/patient/report/:id?" component={PatientReport} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/educationalPlan/:patientId/test/:id" component={EducationalPlan} />
    </Switch>
  );
}
