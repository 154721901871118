import styled from "styled-components";
import { shade } from "polished";
import Tooltip from "../../components/Tooltip";

export const Main = styled.main`
  max-width: 1200px;
  margin: 30px auto 0;
  padding: 0 0 24px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    font-size: 36px;
  }

  > div {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.text.inPrimary};
    border-radius: 10px;
    padding: 8px;
    margin-left: 8px;
    transition: background-color 0.2s;
    text-decoration: none;
    font-weight: bold;

    span {
      margin-left: 8px;
    }

    &:hover {
      background: ${(props) => shade(0.2, props.theme.primary)};
    }
  }
`;

export const Entity = styled.div`
  padding: 10px 20px;
  background: ${(props) => props.theme.background.box};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  h3 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  p {
    color: ${(props) => props.theme.text.secondary};
  }

  & + div {
    margin-top: 8px;
  }
`;

export const IconButton = styled(Tooltip)`
  display: inline;
  margin: 8px 0 8px 8px;

  a,
  button {
    color: ${(props) => props.theme.text.default};
    transition: color 0.2s;
    border: none;

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;

export const ExpertForm = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: auto;
  padding-bottom: 32px;

  gap: 16px;

  grid-template-areas:
    "name name name name name name name name name name cpfcnpj cpfcnpj cpfcnpj rg rg rg"
    "specialty specialty specialty specialty mobile mobile mobile mobile email email email email email email email email"
    "zipcode zipcode zipcode uf city city city city city city city neighborhood neighborhood neighborhood neighborhood neighborhood"
    "address address address address address address address address addressNumber complement complement complement complement complement complement complement"
    "bank bank bank bank bank branch branch branch account account account account pixKey pixKey pixKey pixKey"
    ". . . cancel cancel cancel cancel cancel save save save save save . . .";

  > div {
    margin-top: 0;
  }

  > label {
    text-align: center;

    input {
      margin-right: 8px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  grid-area: buttons;
  gap: 16px;
  padding: 0 128px;
`;

export const ButtonsLinkContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    text-align: center;
  }
`;

export const InputBlockRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
`;

export const ActionButtons = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.background.box};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 10px;
    border-radius: 10px;
    height: 45px;
  }
`;
