import React from 'react'

import { useCan } from '../hooks/useCan'

export function Can({ roles = ['manager'], children }) {
  const userCanSeeComponent = useCan({ roles })

  if (!userCanSeeComponent) {
    return null
  }

  return (
    <>
      {children}
    </>
  )
}
