import styled from "styled-components";
import { shade } from "polished";
import Tooltip from "../../components/Tooltip";

export const Main = styled.main`
  max-width: 1300px;
  margin: 30px auto 0;
  padding: 0 0 24px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    font-size: 36px;
  }

  > div {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.button.background};
    border-radius: 10px;
    padding: 8px;
    margin-left: 8px;
    transition: background-color 0.2s;
    text-decoration: none;
    font-weight: bold;

    span {
      margin-left: 8px;
    }

    &:hover {
      background: ${(props) => shade(0.2, props.theme.primary)};
    }
  }
`;

export const Entity = styled.div`
  padding: 10px 20px;
  background: ${(props) => props.theme.background.box};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  h3 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  p {
    color: ${(props) => props.theme.text.secondary};
  }

  & + div {
    margin-top: 8px;
  }
`;

export const IconButton = styled(Tooltip)`
  display: inline;
  margin: 8px 0 8px 8px;

  a,
  button {
    color: ${(props) => props.theme.text.default};
    transition: color 0.2s;
    border: none;

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;

export const ParagraphText = styled.div`
  h4 {
    font-weight: normal;
    margin-bottom: 8px;
  }

  p {
    margin: 4px 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  gap: 1rem;

  padding-left: 2rem;
  font-size: 12px;
  input {
    margin-right: 0.5rem;
  }
`;

export const EntityForm = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: auto;
  padding-bottom: 32px;

  gap: 16px;

  grid-template-areas:
    "name name name name name name name name cpfcnpj cpfcnpj cpfcnpj rg rg birthday birthday birthday"
    "financialName financialName financialName financialName financialName financialName financialName financialName note note note note note note note note"
    "zipcode zipcode zipcode uf city city city city city city city neighborhood neighborhood neighborhood neighborhood neighborhood"
    "address address address address address address address address addressNumber complement complement complement complement complement complement complement"
    "sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 sector1 "
    "parentage01 parentage01 parentage01 name01 name01 name01 name01 name01 name01 name01 cpf01 cpf01 cpf01 rg01 rg01 rg01 "
    "mobile01 mobile01 mobile01 email01 email01 email01 email01 email01 email01 email01 birthday01 birthday01 birthday01 . . ."
    "parentage02 parentage02 parentage02 name02 name02 name02 name02 name02 name02 name02 cpf02 cpf02 cpf02 rg02 rg02 rg02 "
    "mobile02 mobile02 mobile02 email02 email02 email02 email02 email02 email02 email02 birthday02 birthday02 birthday02 . . ."
    "school school school school school schoolGrade schoolPhone schoolPhone schoolEmail schoolEmail schoolEmail schoolEmail schoolEmail schoolPartner schoolPartner schoolPartner "
    "patientPartner patientPartner patientPartner patientPartner patientPartnerName patientPartnerName patientPartnerName patientPartnerName patientPartnerPhone patientPartnerPhone patientPartnerPhone patientPartnerEmail patientPartnerEmail patientPartnerEmail patientPartnerEmail addPartner"
    "partner partner partner partner partner partner partner partner partner partner partner partner partner partner partner partner"
    "patientTeam patientTeam patientTeam patientTeam patientTeam patientTeam patientTeam patientTeam patientTeam patientTeam patientTeam . . . . addTeam"
    "team team team team team team team team team team team team team team team team"
    ". . . cancel cancel cancel cancel cancel save save save save save . . .";

  > div {
    margin-top: 0;
  }

  > label {
    text-align: center;
    white-space: nowrap;

    input {
      margin-right: 8px;
    }
  }

  > span {
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.text.sector};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  grid-area: buttons;
  gap: 16px;
  padding: 0 128px;
`;

export const ButtonsLinkContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    text-align: center;
  }
`;

export const InputBlockRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
`;

export const ActionButtons = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.background.box};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 10px;
    border-radius: 10px;
    height: 45px;
  }
`;

export const PartnersTable = styled.table`
  grid-area: partner;
  border-collapse: collapse;

  thead {
    text-align: left;

    th {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #333;
    }
  }
`;

export const PartnersRow = styled.tr`
  td {
    padding-top: 0.5rem;
  }
  button {
    margin-left: auto;
  }
`;

export const TeamsTable = styled.table`
  grid-area: team;
  border-collapse: collapse;

  thead {
    text-align: left;
    th {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #333;
    }
  }
`;

export const TeamsRow = styled.tr`
  td {
    padding-top: 0.5rem;
  }
  button {
    margin-left: auto;
  }
`;
export const PatientDetail = styled.div`
  padding: 2rem 0 0;
`;
