import React, { useCallback, useRef } from "react";
import { Container } from "./styles";

export function Input({
  name,
  type = "text",
  cursor = "text",
  label,
  options,
  register,
  rules,
  ...rest
}) {
  const containerRef = useRef(null);

  const handleClick = useCallback(() => {
    containerRef.current.firstChild.focus();
    containerRef.current.firstChild.click();
  }, []);

  return (
    <Container gridPosition={name} type={type || "text"} cursor={cursor}>
      {label && <label htmlFor={name}>{label}</label>}

      <div onClick={handleClick} ref={containerRef}>
        <input
          onClick={handleClick}
          type={type}
          name={name}
          id={name}
          list={options && `${name}_list`}
          {...register(name, rules)}
          {...rest}
        />

        {options && (
          <datalist id={`${name}_list`}>
            {options.map((option) => (
              <option value={option} key={option} />
            ))}
          </datalist>
        )}
      </div>
    </Container>
  );
}
