import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FiEdit2, FiList, FiPlus } from "react-icons/fi";

import { api } from "../../services/api";
import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import { cpfCnpjAddMask, phoneNumberAddMask } from "../../utils";
import { format, parseISO } from "date-fns";

import { Main, Title, Entity, IconButton } from "./styles";
import formatter from "../../utils/formatter";

export default function Patient() {
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    api.get(`/patient`).then((response) => {
      const responseEntities = response.data;

      const formattedEntities = responseEntities.map((entity) => {
        let years_old = "SEM IDADE";
        if (entity.birthday !== null) {
          years_old = formatter.date.yearsOldWithMonth(entity.birthday);
        }

        return {
          ...entity,
          cpfcnpj: cpfCnpjAddMask(entity.cpfcnpj),
          mobile01:
            entity.mobile01 && ` | ${phoneNumberAddMask(entity.mobile01)}`,
          mobile02:
            entity.mobile02 && ` | ${phoneNumberAddMask(entity.mobile02)}`,
          name01: entity.name01 && ` | ${entity.name01}`,
          name02: entity.name02 && ` | ${entity.name02}`,
          age: ` | ${years_old}`,
        };
      });

      setEntities(formattedEntities);
    });
  }, []);

  const statistics = useMemo(() => {
    if (entities.length > 0) {
      return <p>{entities.length} Pacientes cadastrados</p>;
    } else {
      return "Nenhum paciente cadastrado";
    }
  }, [entities.length]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <span>
            <h2>Pacientes</h2>
            {statistics}
          </span>

          <div>
            <Link to="/patient/add">
              <FiPlus size={24} />
            </Link>
          </div>
        </Title>

        {entities.map((entity) => (
          <Entity key={entity.id}>
            <div key={entity.id}>
              <strong>{entity.name}</strong>
              {" | "}
              {format(parseISO(entity.birthday), "dd/MM/yyyy") }
              {entity.age}
              {entity.name01} {entity.mobile01}
              {entity.name02} {entity.mobile02}
            </div>

            <div>
              <IconButton title="Editar">
                <Link to={`/patient/add/${entity.id}`}>
                  <FiEdit2 size={18} />
                </Link>
              </IconButton>

              <IconButton title="Testes">
                <Link to={`/patient/${entity.id}/test`} disable>
                  <FiList size={18} />
                </Link>
              </IconButton>
            </div>
          </Entity>
        ))}
      </Main>
    </>
  );
}
