import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useEducationalPlanStore } from "../../store/educationalPlanStore/educationalPlanStore"

import { api } from "../../services/api";

import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import { Input } from "../../components/Input";
import { Button } from "../../components/NewButton";
import { PopupAlert } from "../../utils/PopupAlert";

import { 
    Main, 
    InputGroup, 
    PageTitle,
    SubmetaContainer,
    InputTitle,
    InputAbility,
    InputDescription,
    InputContainer
} from "./styles"

export default function EducationalPlan() {
    const { patientId, id } = useParams();
    const { register, handleSubmit } = useForm();
    const [selectedGoals, setSelectedGoals] = useState([]);
    
    const storeSelectedDomains = useEducationalPlanStore((state) => state.storeSelectedDomains)
    const storeInterventionSelected = useEducationalPlanStore((state) => state.storeInterventionSelected)

    const extractedDomainsInterventionData = useCallback(() => {
        const arrayDomain = storeSelectedDomains
        const arrayInterventionSelected = storeInterventionSelected

        const selectedInvertionObject = []
        
        // Percorra o array de valores
        for (const valor of arrayInterventionSelected) {
            // Percorra o array de objetos
            for (const object of arrayDomain) {
            // Verifique se o object tem um "id" correspondente ao valor
            if (object.abilities.some(ability => ability.id.toString() === valor)) {
                // Copie as informações relevantes e adicione ao novo array
                selectedInvertionObject.push({
                id: object.id,
                title: object.title,
                level: object.level,
                ability: object.abilities.find(ability => ability.id.toString() === valor)
                });
            }
            }
        }

        setSelectedGoals(selectedInvertionObject);
    },[storeInterventionSelected, storeSelectedDomains])

    useEffect(() => {
        extractedDomainsInterventionData()
    },[extractedDomainsInterventionData])


    const handleSubmitEducationalPlan = handleSubmit(async (data) => {

        try {
            console.log("Dados recebidos pelo formulário", data)
            const result = await api.post("/report/intervencao", {
                patient_id: patientId,
                test_id: id,
                intervencaoData: data
            })
            window.open(result.data);
            console.log("Resposta da api", result)
        } catch (error) {
            console.log(error);
            PopupAlert(`Erro ao gerar!`, "error");
        }
    })

    const daysOfWeek = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'];
    const repetitionCount = 10;
    const submetaRepetitionCount = 8
    const brincadeiras = 6


    return (
        <>
            <Header />
            <Menu />
            <Main>
                <PageTitle>
                    <h1>Plano de ensino</h1>
                </PageTitle>
                <br />

                {selectedGoals && 
                    <form onSubmit={handleSubmitEducationalPlan}>
                        <h1>Metas</h1>
                        {selectedGoals.map((competence, index) =>  (
                            <React.Fragment key={`${competence.id}${index}`}>
                                <InputContainer>
                                    <InputTitle 
                                        value={`${competence.title} - Nível ${competence.level}`}
                                        style={{ width: "612px" }}
                                        readOnly
                                    />

                                    <InputTitle 
                                        {...register(`metas.meta${index}.title`)}
                                        value={`${competence.title}`}
                                        style={{ display: "none" }}
                                    />

                                    <InputTitle 
                                        {...register(`metas.meta${index}.level`)}
                                        value={`Nível ${competence.level}`}
                                        style={{ display: "none" }}
                                    />
                                
                                    <InputAbility 
                                        {...register(`metas.meta${index}.ability`)}
                                        value={`${index + 1} - ${competence.ability.name}`}
                                        style={{ width: "612px" }}
                                        readOnly
                                    />
                                

                                    <InputDescription 
                                        {...register(`metas.meta${index}.description`)}
                                        value={`${competence.ability.description}`}
                                        readOnly
                                    />
                                </InputContainer>
                                <br />
                                <SubmetaContainer>
                                    {Array.from({ length: submetaRepetitionCount }).map((_,i) => (
                                        <div key={i}>
                                            <p>Submeta</p>
                                            <Input
                                                name={`metas.meta${index}.submeta${i}`}
                                                style={{ width: "612px" }}
                                                rules={{ required: false }}
                                                register={register}
                                                maxLength="250"
                                            />
                                        </div>
                                    ))}
                                </SubmetaContainer>
                                <br />
                            </React.Fragment>
                        ))}

                        <>
                            <br />
                            {Array.from({ length: brincadeiras }).map((_, i) => (
                            <div key={i}>
                                <h1>Rotina de atividades</h1>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginRight: "16px" }}>
                                        <p>Tipo de brincadeira</p>
                                        <Input
                                            name={`brincadeiras.brincadeira${i}.rotinaBrincadeira`}
                                            style={{ width: "612px" }}
                                            register={register}
                                            maxLength="250"
                                        />
                                    </div>
                                    <div>
                                        <p>Local</p>
                                        <Input
                                            name={`brincadeiras.brincadeira${i}.brincadeiraLocal`}
                                            style={{ width: "612px" }}
                                            register={register}
                                            maxLength="250"
                                        />
                                    </div>
                                </div>
                                <br />
                                <p>Metas:</p>
                                {selectedGoals.map((competence, index) => (
                                    <React.Fragment key={`${competence.id}${index}`}>
                                        <InputGroup>
                                            <input
                                                {...register(`brincadeiras.brincadeira${i}.metas.meta${index}`)}
                                                type="checkbox"
                                                id={`brincadeiraMeta${competence.id}${i}${index}`}
                                                value={`${index + 1} - ${competence.ability.name}`}
                                            />
                                            {index + 1} - {competence.ability.name}
                                        </InputGroup>
                                    </React.Fragment>
                                ))}
                                <br/>
                            </div>
                            ))}
                        </>

                        <h1>Cronograma</h1>
                        <div style={{ marginBottom: "16px", fontSize: "24px" }}>
                            
                        </div>
                        {Array.from({ length: repetitionCount }).map((_, i) => (
                            <div key={i} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "24px" }}>
                                <div style={{ width: "180px" }}>
                                <p>Horário</p>
                                    <Input
                                        style={{ width: "180px" }}
                                        name={`horarios.horario${i}.horario`}
                                        register={register}
                                        maxLength="250"
                                    />
                                </div>
                                <div>
                                    <p>Terapeuta</p>
                                    <Input
                                        name={`horarios.horario${i}.terapeuta`}
                                        style={{ width: "480px" }}
                                        register={register}
                                        maxLength="250"
                                    />
                                </div>
                                {daysOfWeek.map((day, index) => (
                                <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "24px" }}>
                                    <InputGroup>
                                        <input
                                            {...register(`horarios.horario${i}.diaDaSemana${index}`)}
                                            type="checkbox"
                                            id={`diaDaSemana${i}_${index}`}
                                            value={`X`}
                                        />
                                        <p style={{ fontSize: "12px" }}>{day}</p>
                                    </InputGroup>
                                </div>
                                ))}
                            </div>
                        ))}
                        <br />
                        <div>
                            <p>Coordenador Sênior</p>
                            <Input
                                style={{ width: "385px" }}
                                name="coordenadorSenior"
                                register={register}
                                maxLength="250"
                            />
                        </div>
                        <br />
                        <div>
                            <p>Coordenador Jr</p>
                            <Input
                                style={{ width: "385px" }}
                                name="coordenadorJunior"
                                register={register}
                                maxLength="250"
                            />
                        </div>
                        <br />
                        <div>
                            <p>Início intervenção ABA</p>
                            <Input
                                style={{ width: "385px" }}
                                name="inicioIntervencao"
                                register={register}
                                maxLength="250"
                            />
                        </div>
                        <br />
                        <Button type="submit">Salvar</Button>
                    </form>
                }
            </Main>
        </>
    )
}