import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router";

import { api } from "../../services/api";
import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import { Button } from "../../components/NewButton";

import { PopupAlert } from "../../utils/PopupAlert";
import formatter from "../../utils/formatter";
import { Link } from "react-router-dom";
import { Input } from "../../components/Input";
import { Main, Title, PatientDetail, InputGroup, ParagraphText } from "./styles";
import { CompetenceTitle, Question, QuestionContainer, QuestionTitle } from "./test/styles"

export default function TestForm() {
  const { patientId, test_id } = useParams();
  const { query } = useLocation();
  const { handleSubmit, register, setValue } = useForm();
  //const [competences, setCompetences] = useState([]);

  const [selectedDomain, setSelectedDomain] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [usedDomain, setUsedDomain] = useState([]);

  const [patient, setPatient] = useState();

  useEffect(() => {
    api.post(`/patient/findid`, { id: patientId }).then((response) => {
      let years_old = "SEM IDADE";
      if (response.data.birthday !== null) {
        years_old = formatter.date.yearsOldWithMonth(response.data.birthday);
      }
      setPatient({ ...response.data, years_old });
    });
  }, [test_id, patientId]);


  useEffect(() => {
    if (query && query.test_id) {
      api
        .post(`/report/answers`, { test_id: query.test_id })
        .then((response) => {
          if (response.data.answers) {
            Object.keys(response.data.answers).forEach((key) => {
              const value = response.data.answers[key];
              if (value?.length > 0) setValue(key, value);
            });
          }
        });
    }
  }, [setValue, query]);

  useEffect(() => {
    api.get(`/denverAbility`).then((response) => {
      //setCompetences(response.data);
      setUsedDomain(response.data);
    });

    api
      .get("/testAnswer", { params: { test_id: query.test_id, patientId: query.patient_id } })
      .then((response) => {
        setAnswers(response.data);

        response.data.forEach((answer) => {
          setValue(String(answer.denverAbility_id), String(answer.answer));
        });

        response.data.forEach((answer) => {
          setValue(String(answer.id + "inter"), String(answer.intervention));
        });
      });
  }, [query.patient_id, query.test_id, setValue]);

  useEffect(() => {
    let index = 0;
    let selectTemp = [];

    answers.forEach((answer) => {
      if (index !== answer.denverCompetence_id) {
        index = answer.denverCompetence_id;
        selectTemp.push(
          ...usedDomain.filter(
            (competence) => competence.id === answer.denverCompetence_id
          )
        );
      }
    });
    setSelectedDomain(selectTemp);
  }, [usedDomain, answers]);

  const handleCreateReport = handleSubmit(async (formData) => {

      try {
        const result = await api.post("/report/avaliacao", {
          patient_id: query.patient_id,
          test_id: query.test_id,
          ...formData,
        });

        const pdfUrl = result.data;

        await new Promise((resolve) => setTimeout(resolve, 100));

        window.open(pdfUrl, "_blank", "noopener noreferrer");
      } catch (error) {
        console.log(error);
        PopupAlert(`Erro ao gerar!`, "error");
      }
    })

  

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <h2>Relatório Avaliação Modelo Denver</h2>
        </Title>
        <PatientDetail>
          <p>
            <strong>Nome: </strong>
            <Link
              to={`/patient/add/${patient?.id}`}
              style={{ textDecoration: "none", color: "#353535" }}
            >
              {query?.name}
            </Link>
          </p>
          <p>
            <strong>Idade:</strong>
            <Link
              to={`/patient/add/${patient?.id}`}
              style={{ textDecoration: "none", color: "#353535" }}
            >
              {" "}
              {query?.years_old}
            </Link>
          </p>
        </PatientDetail>

        <form onSubmit={handleCreateReport}>
          <br />
          HISTÓRICO
          <br />
          <br />
          Dados fornecido por:
          <InputGroup>
            <label htmlFor="pais">
              <input
                {...register("parent")}
                type="radio"
                id={"pais"}
                value="pelos pais"
                required
              />
              Pais
            </label>
            <label htmlFor="mae">
              <input
                {...register("parent")}
                type="radio"
                id={"mae"}
                value="pela mãe"
              />
              Mãe
            </label>
            <label htmlFor="pai">
              <input
                {...register("parent")}
                type="radio"
                id={"mae"}
                value="pelo pai"
              />
              Pai
            </label>
          </InputGroup>
          <br />
          {/* 2 - nome_da_crianca */}
          Gestação:
          <InputGroup>
            <label htmlFor="panejada">
              <input
                {...register("gestacao")}
                type="radio"
                id={"panejada"}
                value="planejada"
                required
              />
              Desejada e planejada
            </label>
            <label htmlFor="naopanejada">
              <input
                {...register("gestacao")}
                type="radio"
                id={"naopanejada"}
                value="não planejada, porém desejada"
              />
              não planejada, porém desejada
            </label>
            <label htmlFor="indesejada">
              <input
                {...register("gestacao")}
                type="radio"
                id={"indesejada"}
                value="indesejada"
              />
              indesejada
            </label>
          </InputGroup>
          Gestação mostrou-se:
          <InputGroup>
            <label htmlFor="semintercorrencias">
              <input
                {...register("gestacaomostrou")}
                type="radio"
                id={"semintercorrencias"}
                value="sem intercorrências"
                required
              />
              Sem intercorrências
            </label>
            <label htmlFor="comintercorrencias">
              <input
                {...register("gestacaomostrou")}
                type="radio"
                id={"comintercorrencias"}
                value="com intercorrências"
              />
              Com intercorrências
            </label>
          </InputGroup>
          <br />
          <Input
            name="gestacaoNote"
            label="Descrição de possíveis intercorrências:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          <Input
            name="tempogestacao"
            label="Tempo gestação (semanas):"
            rules={{ required: true }}
            register={register}
            maxLength="2"
          />
          <br />
          Amamentação:
          <InputGroup>
            <label htmlFor="foi">
              <input
                {...register("amamentacao")}
                type="radio"
                id={"foi"}
                value="com dificuldade"
                required
              />
              Com dificuldade
            </label>
            <label htmlFor="naofoi">
              <input
                {...register("amamentacao")}
                type="radio"
                id={"naofoi"}
                value="sem dificuldade"
              />
              Sem dificuldade
            </label>
          </InputGroup>
          <br />
          <Input
            name="amamentacaoTempo"
            label="Amamentato até (meses):"
            rules={{ required: true }}
            register={register}
            maxLength="2"
          />
          <br />
          <Input
            name="observacaoamamentacao"
            label="Observações sobre o período:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          Introdução alimentar:
          <InputGroup>
            <label htmlFor="semintercorrencia">
              <input
                {...register("alimentacao")}
                type="radio"
                id={"semintercorrencia"}
                value="sem intercorrências"
                required
              />
              Sem intercorrências
            </label>
            <label htmlFor="comdificuldades">
              <input
                {...register("alimentacao")}
                type="radio"
                id={"comdificuldades"}
                value="com intercorrências"
              />
              Com intercorrências
            </label>
          </InputGroup>
          <br />
            Aceita alimentos:
          <InputGroup>
            <label htmlFor="aceita">
              <input
                {...register("alimentacaoAceitacao")}
                type="radio"
                id={"aceita"}
                value="aceita"
                required
              />
              Aceita variedade de alimentos
            </label>
            <label htmlFor="oscila">
              <input
                {...register("alimentacaoAceitacao")}
                type="radio"
                id={"oscila"}
                value="oscila na aceitação"
                required
              />
              Oscila na aceitação de variedade de alimentos
            </label>
            <label htmlFor="naoaceita">
              <input
                {...register("alimentacaoAceitacao")}
                type="radio"
                id={"naoaceita"}
                value="não aceita"
              />
              Não aceita variedade de alimentos
            </label>
          </InputGroup>
          <br />
          <Input
            name="alimentacaoNote"
            label="Observações sobre o período de alimentação:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          Sentar, engatinhar e andar ocorreram:
          <InputGroup>
            <label htmlFor="esperado">
              <input
                {...register("motora")}
                type="radio"
                id={"esperado"}
                value="dentro do esperado para a idade"
                required
              />
              Dentro do esperado para a idade
            </label>
            <label htmlFor="leveatraso">
              <input
                {...register("motora")}
                type="radio"
                id={"leveatraso"}
                value="com leve atraso"
                required
              />
              Com leve atraso
            </label>
            <label htmlFor="atrasosignificativo">
              <input
                {...register("motora")}
                type="radio"
                id={"atrasosignificativo"}
                value="com atraso signicativo"
              />
              Com atraso signicativo
            </label>
          </InputGroup>
          <br />
          <Input
            name="obsdesenvolvimentomotor"
            label="Observações sobre o desenvolvimento de habilidades motoras:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          Desfralde diurno:
          <InputGroup>
            <label htmlFor="fazuso">
              <input
                {...register("desfraudediurno")}
                type="radio"
                id={"fazuso"}
                value="ocorreu"
                required
              />
              Ocorreu
            </label>
            <label htmlFor="naofazuso">
              <input
                {...register("desfraudediurno")}
                type="radio"
                id={"naofazuso"}
                value="não ocorreu, a criança ainda faz uso de fraldas"
              />
              Não ocorreu
            </label>
          </InputGroup>
          <br />
          <Input
            name="obsdesfraldediurno"
            label="Se ocorreu, com quantos anos foi o desfralde diurno:"
            rules={{ required: false }}
            register={register}
            maxLength="2"
          />
          <br />
            Desfralde noturno:
          <InputGroup>
            <label htmlFor="ocorreu">
              <input
                {...register("desfraldenoturno")}
                type="radio"
                id={"ocorreu"}
                value="ocorreu"
              />
              Ocorreu
            </label>
            <label htmlFor="naoocorreu">
              <input
                {...register("desfraldenoturno")}
                type="radio"
                id={"naoocorreu"}
                value="não ocorreu, a criança ainda faz uso de fraldas"
                required
              />
              Não ocorreu
            </label>
          </InputGroup>
          <br />
          <Input
            name="obsdesfraldenoturno"
            label="Se ocorreu, com quantos anos foi o desfralde noturno:"
            rules={{ required: false }}
            register={register}
            maxLength="2"
          />
          <br />
          <Input
            name="obsdesfralde"
            label="Observações sobre habilidades de autonomia:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          Habilidades adquiridas:
          <InputGroup>
            <input
              {...register("autonomia1")}
              type="checkbox"
              id={"1a"}
              value="Alimentar-se"
            />
            1. Alimentar-se
            <br />
            <input
              {...register("autonomia2")}
              type="checkbox"
              id={"2a"}
              value="Vestir-se"
            />
            2. Vestir-se
            <br />
            <input
              {...register("autonomia3")}
              type="checkbox"
              id={"3a"}
              value="Andar pela casa"
            />
            3. Andar pela casa
            <br />
            <input
              {...register("autonomia4")}
              type="checkbox"
              id={"4a"}
              value="Brincar sozinho"
            />
            4. Brincar sozinho
            <br />
            <input
              {...register("autonomia5")}
              type="checkbox"
              id={"5a"}
              value="Toma banho"
            />
            5. Toma banho
            <br />
            <input
              {...register("autonomia6")}
              type="checkbox"
              id={"6a"}
              value="Escovar os dentes"
            />
            6. Escovar os dentes
            <br />
            <input
              {...register("autonomia7")}
              type="checkbox"
              id={"7a"}
              value="Penteia cabelo"
            />
            7. Penteia cabelo
            <br />
            <input
              {...register("autonomia8")}
              type="checkbox"
              id={"8a"}
              value="Ajuda/carrega própria mochila ou bolsa"
            />
            8. Ajuda/carrega própria mochila ou bolsa
            <br />
            <input
              {...register("autonomia9")}
              type="checkbox"
              id={"9a"}
              value="Coopera/guarda brinquedos após usá-los"
            />
            9. Coopera/guarda brinquedos após usá-los
            <br />
            <input
              {...register("autonomia10")}
              type="checkbox"
              id={"10a"}
              value="Coopera com rotina de casa (coloca mesa, arruma cama)"
            />
            10. Coopera com rotina de casa (coloca mesa, arruma cama)
            <br />
          </InputGroup>
          <br />
          Habilidades com dificuldades:
          <InputGroup>
            <input
              {...register("autonomiaDificuldades1")}
              type="checkbox"
              id={"1a"}
              value="Alimentar-se"
            />
            1. Alimentar-se
            <br />
            <input
              {...register("autonomiaDificuldades2")}
              type="checkbox"
              id={"2a"}
              value="Vestir-se"
            />
            2. Vestir-se
            <br />
            <input
              {...register("autonomiaDificuldades3")}
              type="checkbox"
              id={"3a"}
              value="Andar pela casa"
            />
            3. Andar pela casa
            <br />
            <input
              {...register("autonomiaDificuldades4")}
              type="checkbox"
              id={"4a"}
              value="Brincar sozinho"
            />
            4. Brincar sozinho
            <br />
            <input
              {...register("autonomiaDificuldades5")}
              type="checkbox"
              id={"5a"}
              value="Toma banho"
            />
            5. Toma banho
            <br />
            <input
              {...register("autonomiaDificuldades6")}
              type="checkbox"
              id={"6a"}
              value="Escovar os dentes"
            />
            6. Escovar os dentes
            <br />
            <input
              {...register("autonomiaDificuldades7")}
              type="checkbox"
              id={"7a"}
              value="Penteia cabelo"
            />
            7. Penteia cabelo
            <br />
            <input
              {...register("autonomiaDificuldades8")}
              type="checkbox"
              id={"8a"}
              value="Ajuda/carrega própria mochila ou bolsa"
            />
            8. Ajuda/carrega própria mochila ou bolsa
            <br />
            <input
              {...register("autonomiaDificuldades9")}
              type="checkbox"
              id={"9a"}
              value="Coopera/guarda brinquedos após usá-los"
            />
            9. Coopera/guarda brinquedos após usá-los
            <br />
            <input
              {...register("autonomiaDificuldades10")}
              type="checkbox"
              id={"10a"}
              value="Coopera com rotina de casa (coloca mesa, arruma cama)"
            />
            10. Coopera com rotina de casa (coloca mesa, arruma cama)
            <br />
          </InputGroup>
          <br />
          {/* 19 - nome_da_crianca */}
            Em relação as brincadeiras:
          <InputGroup>
            <label htmlFor="fazuso">
              <input
                {...register("brinquedo")}
                type="radio"
                id={"fazuso"}
                value="faz uso"
              />
              Faz uso funcional dos brinquedos
            </label>
            <label htmlFor="nemsempre">
              <input
                {...register("brinquedo")}
                type="radio"
                id={"nemsempre"}
                value="nem sempre"
                required
              />
              Nem sempre faz uso funcional dos brinquedos
            </label>
            <label htmlFor="naofazuso">
              <input
                {...register("brinquedo")}
                type="radio"
                id={"naofazuso"}
                value="não faz uso"
              />
              Não faz uso funcional dos brinquedos
            </label>
          </InputGroup>
          <br />
          Habilidade para jogo simbólico:
          <InputGroup>
            <label htmlFor="jogoadequada">
              <input
                {...register("jogosimbolico")}
                type="radio"
                id={"jogoadequada"}
                value="adequada"
              />
              Adequada para idade
            </label>
            <label htmlFor="jogoinadequada">
              <input
                {...register("jogosimbolico")}
                type="radio"
                id={"jogoinadequada"}
                value="inadequada"
                required
              />
              Inadequada para idade
            </label>
          </InputGroup>
          <br />
          Habilidades sociais:
          <InputGroup>
            <label htmlFor="adequadas">
              <input
                {...register("sociais")}
                type="radio"
                id={"adequadas"}
                value="adequada"
                required
              />
              Adequadas
            </label>
            <label htmlFor="inadequadas">
              <input
                {...register("sociais")}
                type="radio"
                id={"inadequadas"}
                value="inadequada"
              />
              Inadequadas
            </label>
            <label htmlFor="sociaisoscilantes">
              <input
                {...register("sociais")}
                type="radio"
                id={"sociaisoscilantes"}
                value="oscilante"
              />
              Oscilantes para a sua idade
            </label>
          </InputGroup>
          <br />
          <ParagraphText>
            <h4>POSSIVEIS COMENTÁRIOS REFERENTE A HABILIDADE DE BRINCAR DA CRIANÇA:</h4>
            <p> A aquisição das habilidades sociais encontra adequada/inadequada/oscilante para a sua idade. </p>
            <Input
              name="habilidadebrincarNote1"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança demonstra habilidade para manipular brinquedos simples como empilhar blocos, encaixar peças ou inserir formas em espaço relacionado</p> 
            <Input
              name="habilidadebrincarNote2"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança demonstra habilidade para seguir instruções de uma, duas ou mais etapas na brincadeira.</p>
            <Input
              name="habilidadebrincarNote3"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança demonstra habilidade para reproduzir ações de uma duas e três etapas na brincadeira com bonecos, como dar comida, tomar banho ou escovar os dentes, manipulando objetos em miniatura.</p>
            <Input
              name="habilidadebrincarNote4"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança utiliza outros objetos com função simbólica em uma brincadeira como por exemplo um barbante que pode virar macarrão ou controle remoto que serve como telefone.</p>
            <Input
              name="habilidadebrincarNote5"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança projeta os bonecos como seres animados e finge que eles interagem entre si, com falas e sequência de histórias.</p>
            <Input
              name="habilidadebrincarNote6"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança realiza jogos imaginários durante a brincadeira, faz mímicas e “finge” realizar uma ação sem usar objetos ou brinquedos.</p>
            <Input
              name="habilidadebrincarNote7"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança cria um roteiro de história em brincadeira como festa de aniversário, compras no mercado ou hora de dormir da boneca.</p>
            <Input
              name="habilidadebrincarNote8"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança demonstra habilidade para realizar trocas e reciprocidade, sugere e aceita novas ideias durante brincadeiras de interação social.</p>
            <Input
              name="habilidadebrincarNote9"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>Criança demonstra preferência por brincar sozinho, com suas próprias regras/brincar em grupo, flexibilizando atividades.</p>
            <Input
              name="habilidadebrincarNote10"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>Criança aceitou interação social com avaliadora e beneficiou-se de dicas e instruções simples.</p>
            <Input
              name="habilidadebrincarNote11"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança realiza/aceita brincadeira de esconde-esconde, cócegas, rodar e balançar.</p>
            <Input
              name="habilidadebrincarNote12"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
          </ParagraphText>
          <br />
          <Input
            name="habilidadebrincar"
            label="Comentários sobre habilidade de brincar da criança:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          <br />
          <ParagraphText>
            <h4>OBSERVAÇÕES REFERENTE AS HABILIDADES SOCIAIS E EMOCIONAIS:</h4>
            <p>A criança busca contato espontâneo com o/a mãe/pai, o/a terapeuta ou outras crianças.</p>
            <Input
              name="habilidadessociaisNote1"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança demonstra afeto sorrindo, chorando, abraçando, beijando, solicitando colo, “fazendo bico”, cruzando os braços, verbalizando “eca”.</p> 
            <Input
              name="habilidadessociaisNote2"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>Criança sorri para parceiro social, atende quando chamado o seu nome, direciona e sustenta contato visual, responde a sons vocais.</p>
            <Input
              name="habilidadessociaisNote3"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança observa o comportamento de outras crianças utilizando brinquedos ou realizando ações.</p>
            <Input
              name="habilidadessociaisNote4"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança realiza imitação de gestos realizados por adultos e segue instruções de 1, 2 ou 3 passos.</p>
            <Input
              name="habilidadessociaisNote5"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>Criança olha para objeto, locais ou pessoas de seu interesse de forma independente. A criança olha para objetos, locais ou pessoas de seu interesse quando direcionado através do apontar.</p>
            <Input
              name="habilidadessociaisNote6"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>Criança inicia e mantém contato interpessoal com adulto e pares.</p>
            <Input
              name="habilidadessociaisNote7"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança identifica e verbaliza sentimentos como triste, feliz, bravo, raiva e nojo, incluindo expressões faciais relacionadas ao evento descrito.</p>
            <Input
              name="habilidadessociaisNote8"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança compreende emoções de outras pessoas relacionadas a situações vivenciadas como por exemplo, se uma criança cai e se machuca, ela sente dor e chora, precisa de ajuda.</p>
            <Input
              name="habilidadessociaisNote9"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança compreende relacionamentos como amizade, namoro e casamento, em que trocas e prazer compartilhado são essenciais mantê-los. A criança compreende os motivos para as pessoas se envolverem em relacionamentos de longo prazo.</p>
            <Input
              name="habilidadessociaisNote10"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
          </ParagraphText>
          <br />
          <Input
            name="habilidadessociais"
            label="Comentários sobre habilidades sociais e emocinais:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          <br />
          Habilidade de fala e linguagem:
          <InputGroup>
            <label htmlFor="adequada">
              <input
                {...register("linguagem")}
                type="radio"
                id={"adequada"}
                value="adequadas"
              />
              Adequadas
            </label>
            <label htmlFor="falainadequada">
              <input
                {...register("linguagem")}
                type="radio"
                id={"falainadequada"}
                value="inadequadas"
              />
              Inadequadas
            </label>
            <label htmlFor="falaoscilantes">
              <input
                {...register("linguagem")}
                type="radio"
                id={"falaoscilantes"}
                value="oscilantes"
              />
              Oscilantes
            </label>
          </InputGroup>
          <br />
          {/* 29 - nome_da_crianca */}
          Habilidades adquiridas:
          <InputGroup>
            <input
              {...register("habilidade1")}
              type="checkbox"
              id={"1"}
              value="Contato visual diante do parceiro social"
            />
            1. Contato visual diante do parceiro social
            <br />
            <input
              {...register("habilidade2")}
              type="checkbox"
              id={"2"}
              value="Balbucio com frequência"
            />
            2. Balbucio com frequência
            <br />
            <input
              {...register("habilidade3")}
              type="checkbox"
              id={"3"}
              value="Forma variedade de sílabas"
            />
            3. Forma variedade de sílabas
            <br />
            <input
              {...register("habilidade4")}
              type="checkbox"
              id={"4"}
              value="Palavras e frases"
            />
            4. Palavras e frases
            <br />
            <input
              {...register("habilidade5")}
              type="checkbox"
              id={"5"}
              value="Realiza pedidos a partir de palavras"
            />
            5. Realiza pedidos a partir de palavras
            <br />
            <input
              {...register("habilidade6")}
              type="checkbox"
              id={"6"}
              value="Coordenando olhar e gestos com parceiro de comunicação"
            />
            6. Coordenando olhar e gestos com parceiro de comunicação
            <br />
            <input
              {...register("habilidade7")}
              type="checkbox"
              id={"7"}
              value="Aponta para itens"
            />
            7. Aponta para itens
          </InputGroup>
          <br />
          Habilidades com dificuldades:
          <InputGroup>
            <input
              {...register("habilidadeDificuldade1")}
              type="checkbox"
              id={"1"}
              value="Contato visual diante do parceiro social"
            />
            1. Contato visual diante do parceiro social
            <br />
            <input
              {...register("habilidadeDificuldade2")}
              type="checkbox"
              id={"2"}
              value="Balbucio com frequência"
            />
            2. Balbucio com frequência
            <br />
            <input
              {...register("habilidadeDificuldade3")}
              type="checkbox"
              id={"3"}
              value="Forma variedade de sílabas"
            />
            3. Forma variedade de sílabas
            <br />
            <input
              {...register("habilidadeDificuldade4")}
              type="checkbox"
              id={"4"}
              value="Palavras e frases"
            />
            4. Palavras e frases
            <br />
            <input
              {...register("habilidadeDificuldade5")}
              type="checkbox"
              id={"5"}
              value="Realiza pedidos a partir de palavras"
            />
            5. Realiza pedidos a partir de palavras
            <br />
            <input
              {...register("habilidadeDificuldade6")}
              type="checkbox"
              id={"6"}
              value="Coordenando olhar e gestos com parceiro de comunicação"
            />
            6. Coordenando olhar e gestos com parceiro de comunicação
            <br />
            <input
              {...register("habilidadeDificuldade7")}
              type="checkbox"
              id={"7"}
              value="Aponta para itens"
            />
            7. Aponta para itens
          </InputGroup>
          <br />
          <br />
          <ParagraphText>
            <h4>OBSERVAÇÕES REFERENTE AS HABILIDADES FALA E LINGUAGEM:</h4>
            <p>Criança verbaliza/não verbaliza pedidos simples coordenando olhar e gestos. A criança pede brinquedos ou brincadeiras, água ou alimentos, usar banheiro.</p>
            <Input
              name="habilidadesfalaelinguagemNote1"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança descreve ações realizadas (“estou jogando bola”), imagens de livros ou situações (como por exemplo, “o cachorro está comendo”).</p> 
            <Input
              name="habilidadesfalaelinguagemNote2"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>Criança relata/não relata histórias vivenciadas e responde perguntas simples.</p>
            <Input
              name="habilidadesfalaelinguagemNote3"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança faz comentários sobre a brincadeira, situações ou comportamento de outras pessoas.</p>
            <Input
              name="habilidadesfalaelinguagemNote4"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>A criança faz perguntas sobre temas trazidos por um parceiro social, demonstra iniciativa na continuidade da conversa.</p>
            <Input
              name="habilidadesfalaelinguagemNote5"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
            <p>Criança relata/não relata vivências e pergunta sobre assuntos trazidos pela terapeuta ou outras crianças.</p>
            <Input
              name="habilidadesfalaelinguagemNote6"
              rules={{ required: false }}
              register={register}
              maxLength="250"
            />
          </ParagraphText>
          <br />
          <Input
            name="habilidadesfalaelinguagem"
            label="Comentário sobre habilidades de fala e linguagem:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          Domínios dentro do nível 1:
          <InputGroup>
            <input
              {...register("dominionivel11")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("dominionivel12")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("dominionivel13")}
              type="checkbox"
              id={"3"}
              value="Competências sociais"
            />
            3. Competências sociais
            <br />
            <input
              {...register("dominionivel14")}
              type="checkbox"
              id={"4"}
              value="Atenção compartilhada"
            />
            4. Imitação
            <br />
            <input
              {...register("dominionivel15")}
              type="checkbox"
              id={"5"}
              value="Cognição"
            />
            5. Cognição
            <br />
            <input
              {...register("dominionivel16")}
              type="checkbox"
              id={"6"}
              value="Jogo"
            />
            6. Jogo
            <br />
            <input
              {...register("dominionivel17")}
              type="checkbox"
              id={"7"}
              value="Motricidade Fina"
            />
            7. Motricidade Fina
            <br />
            <input
              {...register("dominionivel18")}
              type="checkbox"
              id={"8"}
              value="Motricidade Grossa"
            />
            8. Motricidade Grossa
            <br />
            <input
              {...register("dominionivel19")}
              type="checkbox"
              id={"9"}
              value="Comportamento"
            />
            9. Comportamento
            <br />
            <input
              {...register("dominionivel110")}
              type="checkbox"
              id={"10"}
              value="Independência pessoal"
            />
            10. Independência pessoal
          </InputGroup>
          <br />
          Domínios dentro do nível 2:
          <InputGroup>
            <input
              {...register("dominionivel21")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("dominionivel22")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("dominionivel23")}
              type="checkbox"
              id={"3"}
              value="Atenção Conjunta"
            />
            3. Atenção Conjunta
            <br />
            <input
              {...register("dominionivel24")}
              type="checkbox"
              id={"4"}
              value="Competências Socias"
            />
            4. Competências Socias
            <br />
            <input
              {...register("dominionivel25")}
              type="checkbox"
              id={"5"}
              value="Imitação"
            />
            5. Imitação
            <br />
            <input
              {...register("dominionivel26")}
              type="checkbox"
              id={"6"}
              value="Motricidade fina"
            />
            6. Motricidade fina
            <br />
            <input
              {...register("dominionivel27")}
              type="checkbox"
              id={"7"}
              value="Motricidade grossa"
            />
            7. Motricidade grossa
            <br />
            <input
              {...register("dominionivel28")}
              type="checkbox"
              id={"8"}
              value="Jogo"
            />
            8. Jogo
            <br />
            <input
              {...register("dominionivel29")}
              type="checkbox"
              id={"9"}
              value="Jogo: Jogo Independente"
            />
            9. Jogo: Jogo Independente
            <br />
            <input
              {...register("dominionivel210")}
              type="checkbox"
              id={"10"}
              value="Cognição"
            />
            10. Cognição
            <br />
            <input
              {...register("dominionivel211")}
              type="checkbox"
              id={"11"}
              value="Independência pessoal"
            />
            11. Independência pessoal
          </InputGroup>
          <br />
          Domínios dentro do nível 3:
          <InputGroup>
            <input
              {...register("dominionivel31")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("dominionivel32")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("dominionivel33")}
              type="checkbox"
              id={"3"}
              value="Competências sociais"
            />
            3. Competências sociais
            <br />
            <input
              {...register("dominionivel34")}
              type="checkbox"
              id={"4"}
              value="Motricidade fina"
            />
            4. Motricidade fina
            <br />
            <input
              {...register("dominionivel35")}
              type="checkbox"
              id={"5"}
              value="Motricidade grossa"
            />
            5. Motricidade grossa
            <br />
            <input
              {...register("dominionivel36")}
              type="checkbox"
              id={"6"}
              value="Jogo"
            />
            6. Jogo
            <br />
            <input
              {...register("dominionivel37")}
              type="checkbox"
              id={"7"}
              value="Cognição"
            />
            7. Cognição
            <br />
            <input
              {...register("dominionivel38")}
              type="checkbox"
              id={"8"}
              value="Independência pessoal"
            />
            8. Independência pessoal
          </InputGroup>
          <br />
          Domínios dentro do nível 4:
          <InputGroup>
            <input
              {...register("dominionivel41")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("dominionivel42")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("dominionivel43")}
              type="checkbox"
              id={"3"}
              value="Competências sociais"
            />
            3. Competências sociais
            <br />
            <input
              {...register("dominionivel44")}
              type="checkbox"
              id={"4"}
              value="Motricidade fina"
            />
            4. Motricidade fina
            <br />
            <input
              {...register("dominionivel45")}
              type="checkbox"
              id={"5"}
              value="Motricidade grossa"
            />
            5. Motricidade grossa
            <br />
            <input
              {...register("dominionivel46")}
              type="checkbox"
              id={"6"}
              value="Jogo"
            />
            6. Jogo
            <br />
            <input
              {...register("dominionivel47")}
              type="checkbox"
              id={"7"}
              value="Cognição"
            />
            7. Cognição
            <br />
            <input
              {...register("dominionivel48")}
              type="checkbox"
              id={"8"}
              value="Independência pessoal"
            />
            8. Independência pessoal
          </InputGroup>
          <br />
          <br />
          <b>AVALIAÇÃO E CONCLUSÃO:</b>
          <br />
          {/* 32 - data da avaliacao */}
          {/* 33 - grafico de desempenho */}
          {/* <Input
            name="graphNote"
            label="Observação do gráfico de desempenho:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br /> */}
          {/* 36 - nome_da_crianca */}
         {selectedDomain.map((competence) => (
          <React.Fragment key={competence.id}>
            <CompetenceTitle>
              {competence.title} - Nível {competence.level}{" "}
            </CompetenceTitle>

            <br />
                Escreva o nome de 2 habilidades adquiridas ou parcialmente adquiridas de {competence.title}:
                <Input
                  name={`adquiridaUm${competence.id}`}
                  rules={{ required: false }}
                  register={register}
                  maxLength="250"
                />
                <Input
                  name={`adquiridaDois${competence.id}`}
                  rules={{ required: false }}
                  register={register}
                  maxLength="250"
                />
                <br />
                Escreva o nome de 2 habilidades não adquiridas de {competence.title}:
                <Input
                  name={`naoAdquiridaUm${competence.id}`}
                  rules={{ required: false }}
                  register={register}
                  maxLength="250"
                />
                <Input
                  name={`naoAdquiridaDois${competence.id}`}
                  rules={{ required: false }}
                  register={register}
                  maxLength="250"
                />
  
            {competence.abilities.map((ability, index) => (
              <React.Fragment key={index}>
                <QuestionContainer>
                  <Question>
                    <QuestionTitle>
                      {index + 1} - {ability.name}
                    </QuestionTitle>
                  </Question>
                
                  <InputGroup>
                      <label htmlFor={String(ability.id) + "-3"}>
                        <input
                          {...register(String(ability.id))}
                          type="radio"
                          id={ability.id + "-3"}
                          value="3"
                          readOnly={true}
                        />
                        Adquirido
                      </label>

                      <label htmlFor={String(ability.id) + "-2"}>
                        <input
                          {...register(String(ability.id))}
                          type="radio"
                          id={ability.id + "-2"}
                          value="2"
                          readOnly={true}
                        />
                        Parcialmente
                      </label>

                      <label htmlFor={String(ability.id) + "-1"}>
                        <input
                          {...register(String(ability.id))}
                          type="radio"
                          id={ability.id + "-1"}
                          value="1"
                          readOnly={true}
                        />
                        Não adquirido
                      </label>
                  </InputGroup>
                </QuestionContainer>
              </React.Fragment>
            ))}
          </React.Fragment>
         ))}
          <br />
          Habilidades adquiridas dentro do domínio de nível 1:
          <InputGroup>
            <input
              {...register("habilidadenivel11")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("habilidadenivel12")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("habilidadenivel13")}
              type="checkbox"
              id={"3"}
              value="Competências sociais"
            />
            3. Competências sociais
            <br />
            <input
              {...register("habilidadenivel14")}
              type="checkbox"
              id={"4"}
              value="Atenção compartilhada"
            />
            4. Imitação
            <br />
            <input
              {...register("habilidadenivel15")}
              type="checkbox"
              id={"5"}
              value="Cognição"
            />
            5. Cognição
            <br />
            <input
              {...register("habilidadenivel16")}
              type="checkbox"
              id={"6"}
              value="Jogo"
            />
            6. Jogo
            <br />
            <input
              {...register("habilidadenivel17")}
              type="checkbox"
              id={"7"}
              value="Motricidade Fina"
            />
            7. Motricidade Fina
            <br />
            <input
              {...register("habilidadenivel18")}
              type="checkbox"
              id={"8"}
              value="Motricidade Grossa"
            />
            8. Motricidade Grossa
            <br />
            <input
              {...register("habilidadenivel19")}
              type="checkbox"
              id={"9"}
              value="Comportamento"
            />
            9. Comportamento
            <br />
            <input
              {...register("habilidadenivel110")}
              type="checkbox"
              id={"10"}
              value="Independência pessoal"
            />
            10. Independência pessoal
          </InputGroup>
          <br />
          Habilidades adquiridas dentro do domínio de nível 2:
          <InputGroup>
            <input
              {...register("habilidadenivel21")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("habilidadenivel22")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("habilidadenivel23")}
              type="checkbox"
              id={"3"}
              value="Atenção Conjunta"
            />
            3. Atenção Conjunta
            <br />
            <input
              {...register("habilidadenivel24")}
              type="checkbox"
              id={"4"}
              value="Competências Socias: Adultos ou pares"
            />
            4. Competências Socias: Adultos ou pares
            <br />
            <input
              {...register("habilidadenivel25")}
              type="checkbox"
              id={"5"}
              value="Competências Sociais com Pares"
            />
            5. Competências Sociais com Pares
            <br />
            <input
              {...register("habilidadenivel26")}
              type="checkbox"
              id={"6"}
              value="Imitação"
            />
            6. Imitação
            <br />
            <input
              {...register("habilidadenivel27")}
              type="checkbox"
              id={"7"}
              value="Motricidade fina"
            />
            7. Motricidade fina
            <br />
            <input
              {...register("habilidadenivel28")}
              type="checkbox"
              id={"8"}
              value="Motricidade grossa"
            />
            8. Motricidade grossa
            <br />
            <input
              {...register("habilidadenivel29")}
              type="checkbox"
              id={"9"}
              value="Jogo"
            />
            9. Jogo
            <br />
            <input
              {...register("habilidadenivel210")}
              type="checkbox"
              id={"10"}
              value="Jogo: Jogo Independente"
            />
            10. Jogo: Jogo Independente
            <br />
            <input
              {...register("habilidadenivel211")}
              type="checkbox"
              id={"11"}
              value="Cognição"
            />
            11. Cognição
            <br />
            <input
              {...register("habilidadenivel212")}
              type="checkbox"
              id={"12"}
              value="Independência pessoal"
            />
            12. Independência pessoal
          </InputGroup>
          <br />
          Habilidades adquiridas dentro do domínio de nível 3:
          <InputGroup>
            <input
              {...register("habilidadenivel31")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("habilidadenivel32")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("habilidadenivel33")}
              type="checkbox"
              id={"3"}
              value="Competências sociais: Adultos e Pares"
            />
            3. Competências sociais: Adultos e Pares
            <br />
            <input
              {...register("habilidadenivel34")}
              type="checkbox"
              id={"4"}
              value="Motricidade fina"
            />
            4. Motricidade fina
            <br />
            <input
              {...register("habilidadenivel35")}
              type="checkbox"
              id={"5"}
              value="Motricidade grossa"
            />
            5. Motricidade grossa
            <br />
            <input
              {...register("habilidadenivel36")}
              type="checkbox"
              id={"6"}
              value="Jogo"
            />
            6. Jogo
            <br />
            <input
              {...register("habilidadenivel37")}
              type="checkbox"
              id={"7"}
              value="Cognição"
            />
            7. Cognição
            <br />
            <input
              {...register("habilidadenivel38")}
              type="checkbox"
              id={"8"}
              value="Independência pessoal"
            />
            8. Independência pessoal
          </InputGroup>
          <br />
          Habilidades adquiridas dentro do domínio de nível 4:
          <InputGroup>
            <input
              {...register("habilidadenivel41")}
              type="checkbox"
              id={"1"}
              value="Comunicação Receptiva"
            />
            1. Comunicação Receptiva
            <br />
            <input
              {...register("habilidadenivel42")}
              type="checkbox"
              id={"2"}
              value="Comunicação Expressiva"
            />
            2. Comunicação Expressiva
            <br />
            <input
              {...register("habilidadenivel43")}
              type="checkbox"
              id={"3"}
              value="Competências sociais"
            />
            3. Competências sociais
            <br />
            <input
              {...register("habilidadenivel44")}
              type="checkbox"
              id={"4"}
              value="Motricidade fina"
            />
            4. Motricidade fina
            <br />
            <input
              {...register("habilidadenivel45")}
              type="checkbox"
              id={"5"}
              value="Motricidade grossa"
            />
            5. Motricidade grossa
            <br />
            <input
              {...register("habilidadenivel46")}
              type="checkbox"
              id={"6"}
              value="Jogo"
            />
            6. Jogo
            <br />
            <input
              {...register("habilidadenivel47")}
              type="checkbox"
              id={"7"}
              value="Cognição"
            />
            7. Cognição
            <br />
            <input
              {...register("habilidadenivel48")}
              type="checkbox"
              id={"8"}
              value="Independência pessoal"
            />
            8. Independência pessoal
          </InputGroup>
          <br />
          {/* <Input
            name="domainNoteAvaliacao"
            label="Observação domínios avaliados:"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br /> */}
          <h4>Habilidades sociais e afetivas que demonstra dificuldade:</h4>
          <p>Atenção compartilhada</p>
          <Input
            name="habilidadessociaisdificuldade1"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Realizar imitação motora</p>
          <Input
            name="habilidadessociaisdificuldade2"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Coordenar gestos motores</p>
          <Input
            name="habilidadessociaisdificuldade3"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Iniciar e manter contato visual</p>
          <Input
            name="habilidadessociaisdificuldade4"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Antecipar rotina</p>
          <Input
            name="habilidadessociaisdificuldade5"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Apontar para objetos de desejo</p>
          <Input
            name="habilidadessociaisdificuldade6"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Coordenar olhar entre objeto de desejo e parceiro social</p>
          <Input
            name="habilidadessociaisdificuldade7"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Verbalizar palavras</p>
          <Input
            name="habilidadessociaisdificuldade8"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Realizar pedidos através de palavras</p>
          <Input
            name="habilidadessociaisdificuldade9"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Responder perguntas</p>
          <Input
            name="habilidadessociaisdificuldade10"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Relatar histórias</p>
          <Input
            name="habilidadessociaisdificuldade11"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <p>Iniciar e manter relacionamento interpessoal</p>
          <Input
            name="habilidadessociaisdificuldade12"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <br />
          <Input
            name="hours"
            label="Horas por semana recebido:"
            rules={{ required: true }}
            register={register}
            maxLength="2"
          />{" "}
          <br />
          <p>Qual profissional vai assinar esse relatório?</p>
          <br />
          <InputGroup>
            <label htmlFor="AmandaNucciCarrara">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"AmandaNucciCarrara"}
                value="AmandaNucciCarrara"
                required
              />
              Amanda Nucci Carrara
            </label>
            <label htmlFor="AnaLuisaPolizelLibardi">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"AnaLuisaPolizelLibardi"}
                value="AnaLuisaPolizelLibardi"
                required
              />
              Ana Luisa Polizel Libardi
            </label>
            <label htmlFor="GiovannaMoreira">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"GiovannaMoreira"}
                value="GiovannaMoreira"
                required
              />
              Giovanna Moreira
            </label>
            <label htmlFor="RenataCoradiLeme">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"RenataCoradiLeme"}
                value="RenataCoradiLeme"
                required
              />
              Renata Coradi Leme
            </label>
            <label htmlFor="RafaelaLopes">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"RafaelaLopes"}
                value="RafaelaLopes"
                required
              />
              Rafaela Lopes
            </label>
            <label htmlFor="AlineRibeiroLopes">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"AlineRibeiroLopes"}
                value="AlineRibeiroLopes"
                required
              />
              Aline Ribeiro Lopes
            </label>
            <label htmlFor="AnnaPaulaBadelino">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"AnnaPaulaBadelino"}
                value="AnnaPaulaBadelino"
                required
              />
              Anna Paula Badelino
            </label>
            <label htmlFor="OutraProfissional">
              <input
                {...register("assinaturaProfissional")}
                type="radio"
                id={"OutraProfissional"}
                value="OutraProfissional"
                required
              />
              Outra profissional
            </label>
          </InputGroup>
          <br />
          <>
            <br />
            <div>
              <p>Nome do profissional</p>
              <Input
                style={{ width: "385px" }}
                name="nomeProfissional"
                required
                register={register}
                maxLength="250"
              />
            </div>
            <br />
            <div>
              <p>Registro do conselho</p>
              <Input
                style={{ width: "385px" }}
                name="registroDoConselho"
                required
                register={register}
                maxLength="250"
              />
            </div>
            <br />
            <div>
              <p>Outras informações</p>
              <Input
                style={{ width: "385px" }}
                name="outrasInformacoes"
                register={register}
                maxLength="250"
              />
            </div>
            <br />
          </>
          <br />
          <Button>Gerar</Button>
        </form>
      </Main>
    </>
  );
}
