import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  :root {
    font-size: 60%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-family: "Montserrat", sans-serif;
  }

  html, body, #root {
    height: 100vh;
  }

  body {
    background: ${props => props.theme.background.default};
  }

  body, input, select, button, textarea {
    font: 500 1.6rem Montserrat;
    color: ${props => props.theme.text.default};
  }

  button {
    cursor: pointer;
  }

  .swal-text {
    text-align: center;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    width: 100%;
    max-width: 680px;
    max-height: 600px;
    overflow-y: auto;
    background: ${props => props.theme.background.default};
    padding: 3rem;
    position: relative;
    border-radius: 10px;
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: none;
    background: transparent;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  ::selection {
    color: ${props => props.theme.text.inPrimary};
    background: ${props => props.theme.primary};
  }

  @media (min-width: 700px) {
    :root {
      font-size: 62.5%;
    }
  }

  @media print {
    .no-print, .no-print * {
      display: none !important;
    }
  }
`
