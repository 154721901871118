import styled from "styled-components";
import { shade } from "polished";
import Tooltip from "../../components/Tooltip";

export const Main = styled.main`
  max-width: 1200px;
  margin: 30px auto 0;
  padding: 0 0 24px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    font-size: 36px;
  }

  > div {
    display: flex;
  }
`;

export const Level = styled.div`
  padding: 10px 20px;
  background: ${(props) => props.theme.background.box};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;

  h3 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  p {
    color: ${(props) => props.theme.text.secondary};
  }

  & + div {
    margin-top: 8px;
  }
`;

export const Entity = styled.div`
  padding: 10px 20px;
  background: ${(props) => props.theme.background.box};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  h3 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  p {
    color: ${(props) => props.theme.text.secondary};
  }

  & + div {
    margin-top: 8px;
  }
`;

export const Domain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    text-align: center;
  }

  a {
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.text.inPrimary};
    border-radius: 10px;
    margin: 2px;
    padding: 8px;
    margin-left: 8px;
    transition: background-color 0.2s;
    text-decoration: none;
    font-weight: bold;

    span {
      margin-left: 8px;
    }

    &:hover {
      background: ${(props) => shade(0.2, props.theme.primary)};
    }
  }
`;

export const Description = styled.div`
  font-size: 12px;
  padding-left: 30px;
  padding-bottom: 5px;
`;

export const IconButton = styled(Tooltip)`
  display: inline;
  margin: 8px 0 8px 8px;

  a,
  button {
    color: ${(props) => props.theme.text.default};
    transition: color 0.2s;
    border: none;

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;

export const PatientDetail = styled.div`
  padding: 2rem 0 0;
`;
