import axios from "axios";

const token = localStorage.getItem("@socialmentes/token");

export const api = axios.create({
  baseURL:'https://backend.socialmentes.net/api',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

//http://172.20.0.1:3000/api
//http://192.168.222.137:3000/qpi
//`${process.env.REACT_APP_BASE_URL}/api`
//'http://172.26.160.1:3000/api'
//'https://backend.socialmentes.net/api'
//http://192.168.1.15:3000/api