import React, { useEffect, useMemo, useState } from "react";

import { format } from "date-fns";

import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import formatter from "../../utils/formatter";
import { api } from "../../services/api";
import { Main, Lasts } from "./styles";
import { VersionDisplay } from "../../components/VersionDisplay";

export default function Home() {
  const [tests, setTests] = useState([]);
  const [newTests, setNewTests] = useState([]);
  const [newPatients, setNewPatients] = useState([]);

  useEffect(() => {
    api
      .get(`/test/finished`, { params: { qtde: 3, finished: true } })
      .then((response) => {
        setTests([...response.data]);
      });
  }, []);

  useEffect(() => {
    api
      .get(`/test/finished`, { params: { qtde: 3, finished: false } })
      .then((response) => {
        setNewTests([...response.data]);
      });
  }, []);

  useEffect(() => {
    api.get(`/patient/last`, { params: { qtde: 3 } }).then((response) => {
      setNewPatients([...response.data]);
    });
  }, []);

  const formattedTests = useMemo(() => {
    return tests.map((key) => ({
      ...key,
      type: key.type === "denver" ? "Denver" : "-",
      dateFinished: format(new Date(key.dateFinished), "dd/MM/yyyy"),
    }));
  }, [tests]);

  const formattedNewTests = useMemo(() => {
    return newTests.map((key) => ({
      ...key,
      type: key.type === "denver" ? "Denver" : "-",
      dateFinished: format(new Date(key.dateFinished), "dd/MM/yyyy"),
    }));
  }, [newTests]);

  const formattedNewPatients = useMemo(() => {
    return newPatients.map((key) => ({
      ...key,
      years_old: formatter.date.yearsOldWithMonth(key.birthday),
      date: format(new Date(key.created_at), "dd/MM/yyyy"),
    }));
  }, [newPatients]);

  return (
    <>
      <Header />
      <Menu />
      <VersionDisplay />
      <Main>
        <Lasts>
          <h3>Últimos relatórios finalizados</h3>
          {formattedTests.map((key) => (
            <li>
              <p>
                {key.id.toString().padStart(4, "0")} | {key.type} | {key.name} |{" "}
                {key.dateFinished}
              </p>
            </li>
          ))}
          <br />
          <br />
          <h3>Últimos testes cadastrados</h3>
          {formattedNewTests.map((key) => (
            <li>
              <p>
                {key.id.toString().padStart(4, "0")} | {key.type} | {key.name} |{" "}
                {key.dateFinished}
              </p>
            </li>
          ))}
          <br />
          <br />
          <h3>Últimos pacientes</h3>
          {formattedNewPatients.map((key) => (
            <li>
              <p>
                {key.name} | Idade: {key.years_old} | {key.date}
              </p>
            </li>
          ))}
        </Lasts>
      </Main>
    </>
  );
}
