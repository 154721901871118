import { useEffect, useState } from "react";

import { api } from "../../services/api";
import { Container } from "./styles";

export function VersionDisplay() {
  const [version, setVersion] = useState("");

  useEffect(() => {
    api
      .get("about/webVersion")
      .then((response) => setVersion(response.data))
      .catch(() => console.warn("Erro ao requisitar versão"));
  }, []);

  return <Container>{version}</Container>;
}
