import swal from "sweetalert";

export function PopupAlert(
  mensagem = "Atenção",
  tipoAlerta = "success",
  title,
  timer = 4000
) {
  swal({
    title,
    text: mensagem,
    icon: tipoAlerta,
    buttons: {
      cancel: false,
      confirm: true,
    },
    timer: timer,
  });
}

export async function PopupConfirm({
  title,
  message,
  cancelMessage,
  successMessage,
}) {
  return swal({
    title,
    text: message,
    buttons: {
      catch: {
        text: cancelMessage,
        value: false,
      },
      success: {
        text: successMessage,
        value: true,
      },
    },
  });
}
