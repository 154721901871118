import { useContext } from 'react'

import { AuthContext } from '../contexts/AuthContext'
import { validateUserPermissions } from '../utils/validateUserPermissions'

export function useCan({ roles = ["manager"] }) {
  const { user, isAuthenticated } = useContext(AuthContext)

  if (!isAuthenticated) {
    return false
  }

  const userHavePermission = validateUserPermissions({
    user,
    roles,
  })

  return userHavePermission
}
