import React, { useEffect, useMemo, useState } from "react";
import { FiChevronRight } from "react-icons/fi";

import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import { api } from "../../services/api";

import { format } from "date-fns";
import { Main, Title, Entity } from "./styles";

export default function Test() {
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    api.get(`/test/finished`, { params: { finished: true } }).then((response) => {
      setEntities([...response.data]);
    });
  }, []);

  const formattedEntities = useMemo(() => {
    return entities.map((entity) => ({
      ...entity,
      type: entity.type === "denver" ? "Denver" : "-",
      created_at: format(new Date(entity.created_at), "dd/MM/yyyy 'às' hh:mm"),
    }));
  }, [entities]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <span>
            <h2>Testes Finalizados</h2>
          </span>
        </Title>

        {formattedEntities.map((entity) => (
          <Entity
            key={entity.id}
            to={`/patient/${entity.patientId}/test/${entity.id}`}
          >
            <div>
              {entity.id.toString().padStart(4, "0")} | Criado:{" "}
              {entity.created_at} | Tipo: {entity.type} | Finalizado |{" "}
              {entity.name}
            </div>

            <FiChevronRight size={18} />
          </Entity>
        ))}
      </Main>
    </>
  );
}
