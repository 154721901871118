import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { api } from "../../services/api";
import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";

import { Main, Title, Level, Domain } from "./styles";

export default function Denver() {
  const [denver, setDenver] = useState([]);
  let levelPrev = 0;

  useEffect(() => {
    api.get(`/denvercompetence`).then((response) => {
      setDenver(response.data);
    });
  }, []);

  const checkLevel = (levelReceived) => {
    if (levelReceived !== levelPrev) {
      levelPrev = levelReceived;
      return <Level>Nível {levelReceived}</Level>;
    }
  };

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <span>
            <h2>Denver</h2> Competências
          </span>
        </Title>

        {denver.map((key) => (
          <>
            {checkLevel(key.level)}
            <Domain>
              <Link to={`/denver/competence/${key.id}`}>{key.domain}</Link>
            </Domain>
          </>
        ))}
      </Main>
    </>
  );
}
