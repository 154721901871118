import styled from "styled-components";
import { shade } from "polished";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: block;
  position: relative;
  margin: 0 -0.5px;
  font-weight: bold;
  transition: background-color 0.2s;

  > a {
    color: ${props => props.theme.menu.text};
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;
    padding: 8px;
  }

  nav {
    position: absolute;
    display: flex;

    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;

    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    flex-direction: column;
    top: 100%;
    background: ${props => props.theme.menu.subMenu.background};
    border-radius: 0px 0px 10px 10px;
  }

  ::before {
    content: '';
    position: absolute;
    background: ${props => props.theme.shape.default};
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
  }

  ::after {
    content: '';
    position: absolute;
    background: ${props => props.theme.shape.default};
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
  }

  &:hover {
    background: ${props => shade(0.15, props.theme.menu.background)};

    nav {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const SubMenuItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  position: relative;

  padding: 8px 8px 16px;
  white-space: nowrap;
  color: ${props => props.theme.text.secondary};
  text-decoration: none;
  transition: background-color 0.2s;

  svg {
    margin-left: 32px;
  }

  ::after {
    content: '';
    position: absolute;
    background: ${props => props.theme.text.secondary};
    width: 90%;
    height: 1px;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  :hover {
    background: ${props => props.theme.menu.subMenu.hover};
  }
`
