import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

import { api } from "../../services/api";
import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";

import { Main, Title, Level, IconButton, Description } from "./styles";

export default function Denver() {
  const [question, setQuestion] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    api.post(`/denverability/findid`, { id }).then((response) => {
      setQuestion(response.data);
    });
  }, [id]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <span>
            <h2>Denver - Domínio</h2>
          </span>
        </Title>

        {question.map((key) => (
          <>
            <Level>
              <div>
                {key.denverQuestion_id} - {key.name}
              </div>
              <div>
                <IconButton title="Editar">
                  <Link to={`/`}>
                    <FiEdit2 size={18} />
                  </Link>
                </IconButton>
              </div>
            </Level>
            <Description>{key.description}</Description>
          </>
        ))}
      </Main>
    </>
  );
}
