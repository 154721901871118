import React, { useContext } from "react";
import { Redirect, Route as ReactDomRoute } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";
import { useCan } from "../hooks/useCan";

export function Route({ component: Component, permissions, ...rest }) {
  const { user } = useContext(AuthContext);
  const userCanSeePage = useCan({ roles: permissions });

  if (!user) {
    return <ReactDomRoute {...rest} component={Component} />
  }

  return (
    <ReactDomRoute
      {...rest}
      render={(props) => {
        if (permissions) {
          return userCanSeePage
            ? <Component {...props} />
            : <Redirect to={{ pathname: "/main", state: { from: props.location } }} />
        }

        return <Component {...props} />
      }}
    />
  )
}
