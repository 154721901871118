import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import Modal from "react-modal";

import Routes from "./routers";
import GlobalStyle from "./assets/styles/global";
import theme from "./assets/styles/themes/default";
import { AuthProvider } from "./contexts/AuthContext";

import "./assets/styles/global.css";
import "./pages/styles.css";

Modal.setAppElement("#root");

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Routes />
          <GlobalStyle />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
