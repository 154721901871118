import React, { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { api } from "../services/api";
import { PopupAlert } from "../utils/PopupAlert";

export const AuthContext = createContext({});

let isRefreshing = false;
let failedRequestQueue = [];

const publicPaths = new Set(["/pub1/test1/", "/pub2/test2"]);

export function AuthProvider({ children }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [user, setUser] = useState();
  const isAuthenticated = Boolean(user);

  const signOut = useCallback(() => {
    localStorage.removeItem("@socialmentes/token");
    localStorage.removeItem("@socialmentes/refreshToken");

    setUser(undefined);
    history.replace("/");
  }, [history]);

  useEffect(() => {
    let isPublicPath = false;

    publicPaths.forEach((path) => {
      if (pathname.startsWith(path)) isPublicPath = true;
    });

    if (isPublicPath) return;

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          if (error.response.data?.code === "token.expired") {
            const refreshToken = localStorage.getItem(
              "@socialmentes/refreshToken"
            );
            const originalConfig = error.config;

            if (!isRefreshing) {
              isRefreshing = true;

              api
                .post("sessions/refresh", { refreshToken })
                .then((response) => {
                  const { token, refreshToken } = response.data;

                  localStorage.setItem("@socialmentes/token", token);
                  localStorage.setItem(
                    "@socialmentes/refreshToken",
                    refreshToken
                  );

                  api.defaults.headers["Authorization"] = `Bearer ${token}`;

                  failedRequestQueue.forEach((request) =>
                    request.onSuccess(token)
                  );
                  failedRequestQueue = [];
                })
                .catch((err) => {
                  failedRequestQueue.forEach((request) =>
                    request.onFailure(err)
                  );
                  failedRequestQueue = [];

                  signOut();
                })
                .finally(() => {
                  isRefreshing = false;
                });
            }

            return new Promise((resolve, reject) => {
              failedRequestQueue.push({
                onSuccess: (token) => {
                  originalConfig.headers["Authorization"] = `Bearer ${token}`;

                  resolve(api(originalConfig));
                },

                onFailure: (err) => {
                  reject(err);
                },
              });
            });
          } else {
            signOut();
          }
        }

        return Promise.reject(error);
      }
    );

    const token = localStorage.getItem("@socialmentes/token");

    if (token) {
      api.defaults.headers["Authorization"] = `Bearer ${token}`;

      api
        .get("users/me")
        .then((response) => {
          setUser(response.data);
        })
        .catch(() => {
          signOut();
        });
    } else {
      signOut();
    }
  }, [pathname, signOut]);

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const response = await api.post("sessions", { email, password });

        const { token, refreshToken, user } = response.data;

        localStorage.setItem("@socialmentes/token", token);
        localStorage.setItem("@socialmentes/refreshToken", refreshToken);

        setUser(user);
        api.defaults.headers["Authorization"] = `Bearer ${token}`;

        history.push("/main");
      } catch (err) {
        console.error(err);
        PopupAlert("Erro de autenticação", "error", "Email ou senha inválido");
      }
    },
    [history]
  );

  return (
    <AuthContext.Provider value={{ signIn, isAuthenticated, user, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}
