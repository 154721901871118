import React from "react";

import { Container } from "./styles";

export function Select({
  name,
  options,
  label,
  rules,
  register,
  style,
  ...rest
}) {
  const registerPreventNull = register || (() => {});

  return (
    <Container gridPosition={name} style={style}>
      {label && <label htmlFor={name}>{label}</label>}

      <div>
        <select {...rest} name={name} {...registerPreventNull(name, rules)}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </Container>
  );
}
