import { shade } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Tooltip from "../../components/Tooltip";

export const Main = styled.main`
  max-width: 1400px;
  margin: 30px auto 0;
  padding: 0 0 24px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    font-size: 36px;
  }

  > div {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.button.background};
    border-radius: 10px;
    padding: 8px;
    margin-left: 8px;
    transition: background-color 0.2s;
    text-decoration: none;
    font-weight: bold;

    span {
      margin-left: 8px;
    }

    svg {
      margin-right: 0.5rem;
    }

    &:hover {
      background: ${(props) => shade(0.2, props.theme.primary)};
    }
  }
`;

export const PatientDetail = styled.div`
  padding: 2rem 0 0;
`;

export const CompetenceTitle = styled.h3`
  margin-top: 4rem;
  border-bottom: 1px solid black;
`;

export const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333;
  margin-top: 0.5rem;
`;

export const Question = styled.div`
  flex: 1;
`;

export const QuestionTitle = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-align: justify;
`;

export const Description = styled.div`
  font-size: 12px;
  padding-bottom: 5px;
  text-align: justify;
`;

export const InputGroup = styled.div`
  display: flex;
  gap: 1rem;

  padding-left: 2rem;

  input {
    margin-right: 0.1rem;
  }
  label {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.5rem;
`;

export const Entity = styled(Link)`
  padding: 10px 20px;
  background: ${(props) => props.theme.background.box};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: inherit;

  transition: 0.2s;

  h3 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  p {
    color: ${(props) => props.theme.text.secondary};
  }

  & + a {
    margin-top: 8px;
  }

  :hover {
    filter: brightness(0.9);
  }
`;

export const IconButton = styled(Tooltip)`
  display: inline;
  margin: 8px 0 8px 8px;

  a,
  button {
    color: ${(props) => props.theme.text.default};
    transition: color 0.2s;
    border: none;

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;
