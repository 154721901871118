export function validateUserPermissions({ user, roles }) {
  if (roles?.length > 0) {
    const hasPermission = roles.some(role => {
      return user?.role === role
    })

    if (!hasPermission) {
      return false
    }
  }

  return true
}
