import React from "react";
import { Link } from "react-router-dom";

import { Container, SubMenuItem } from "./styles";
import { Can } from "../../Can";

export default function MenuItem({ label, href = "#", subMenu = [] }) {
  return (
    <Container>
      <Link to={href}>{label}</Link>

      {subMenu.length > 0 && (
        <nav>
          {subMenu.map((item, index) => {
            if (item.permissions) {
              return (
                <Can roles={item.permissions} key={item.href}>
                  <SubMenuItem to={item.href} key={index} >
                    {item.label}
                  </SubMenuItem>
                </Can>
              )
            }

            return (
              <SubMenuItem to={item.href} key={item.href}>
                {item.label}
              </SubMenuItem>
            )
          })}
        </nav>
      )}
    </Container>
  )
}
