import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router";

import { api } from "../../services/api";
import { Menu } from "../../components/Menu";
import { Header } from "../../components/Header";
import { Button } from "../../components/NewButton";

import { PopupAlert } from "../../utils/PopupAlert";
import formatter from "../../utils/formatter";
import { Link } from "react-router-dom";
import { Input } from "../../components/Input";
import { Main, Title, PatientDetail, InputGroup } from "./styles";

export default function TestForm() {
  const { patientId, test_id } = useParams();
  const { query } = useLocation();
  const { handleSubmit, register, setValue } = useForm();

  const [patient, setPatient] = useState();

  useEffect(() => {
    api.post(`/patient/findid`, { id: patientId }).then((response) => {
      let years_old = "SEM IDADE";
      if (response.data.birthday !== null) {
        years_old = formatter.date.yearsOldWithMonth(response.data.birthday);
      }
      setPatient({ ...response.data, years_old });
    });

    api
      .get("/testAnswer", { params: { test_id, patientId } })
      .then((response) => {
        response.data.forEach((answer) => {
          setValue(String(answer.denverAbility_id), String(answer.answer));
        });
      });
  }, [test_id, patientId, setValue]);

  const handleCreateReport = handleSubmit(async (formData) => {
    try {
      const result = await api.post("/report/intervencao", {
        patient_id: query.patient_id,
        test_id: query.test_id,
        ...formData,
      });
      window.open(result.data);
    } catch (error) {
      console.log(error);
      PopupAlert(`Erro ao gerar!`, "error");
    }
  });

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <h2>Relatório Avaliação Modelo Denver</h2>
        </Title>

        <PatientDetail>
          <p>
            <strong>Nome: </strong>
            <Link
              to={`/patient/add/${patient?.id}`}
              style={{ textDecoration: "none", color: "#353535" }}
            >
              {query?.name}
            </Link>
          </p>
          <p>
            <strong>Idade:</strong>
            <Link
              to={`/patient/add/${patient?.id}`}
              style={{ textDecoration: "none", color: "#353535" }}
            >
              {" "}
              {query?.years_old}
            </Link>
          </p>
        </PatientDetail>

        <form onSubmit={handleCreateReport}>
          <br />
          HISTÓRICO
          <br />
          <br />
          Dificuldades na aquisição das respostas sociais e de comunicação
          descritas abaixo:
          <br />
          <input
            {...register("dificult1")}
            type="checkbox"
            id={"1"}
            value="Realizar e sustentar contato visual"
          />
          1. Realizar e sustentar contato visual
          <br />
          <input
            {...register("dificult2")}
            type="checkbox"
            id={"2"}
            value="Atender ao chamado do seu nome"
          />
          2. Atender ao chamado do seu nome
          <br />
          <input
            {...register("dificult3")}
            type="checkbox"
            id={"3"}
            value="Buscar e manter relações com crianças e pessoas"
          />
          3. Buscar e manter relações com crianças e pessoas
          <br />
          <input
            {...register("dificult4")}
            type="checkbox"
            id={"4"}
            value="Aceitar e manter contato físico"
          />
          4. Aceitar e manter contato físico
          <br />
          <input
            {...register("dificult5")}
            type="checkbox"
            id={"5"}
            value="Emitir sons e balbucios espontaneamente dirigido a outras pessoas"
          />
          Aquisição das habilidades motoras como se sentar, engatinhar e andar
          ocorreram:
          <InputGroup>
            <label htmlFor="In">
              <input
                {...register("hability")}
                type="radio"
                id={"true"}
                value="true"
              />
              Dentro do esperado para a idade
            </label>

            <label htmlFor="Out">
              <input
                {...register("hability")}
                type="radio"
                id={"false"}
                value="false"
              />
              Com atraso para a idade
            </label>
          </InputGroup>
          <Input
            name="habilityNote"
            label="Observação"
            rules={{ required: false }}
            register={register}
            maxLength="250"
          />
          <Button>Gerar</Button>
        </form>
      </Main>
    </>
  );
}
