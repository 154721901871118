import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { api } from "../../services/api";
import { Header } from "../../components/Header";
import { Input } from "../../components/Input";
import { InputMask } from "../../components/InputMask";
import { Select } from "../../components/Select";
import { Menu } from "../../components/Menu";
import { PopupAlert } from "../../utils/PopupAlert";
import { Button } from "../../components/NewButton";

import { useHistory, useParams } from "react-router-dom";
import { Main, Title, ExpertForm } from "./styles";

export default function ExpertAdd() {
  const params = useParams();
  const { goBack, push } = useHistory();
  const [entity, setEntity] = useState([]);
  const [banks, setBanks] = useState([]);
  const { register, handleSubmit, setValue } = useForm();

  // GET Banks Name's from API
  useEffect(() => {
    api.get("bank").then((response) => {
      const formattedBanks = response.data.map((bank) => {
        return {
          value: `${bank.id}`,
          label: `${bank.id} - ${bank.name}`,
        };
      });
      setBanks(formattedBanks);
    });
  }, []);

  // GET data from API
  useEffect(() => {
    if (params.id) {
      api
        .post(`/expert/findid`, {
          id: params.id,
        })
        .then((response) => {
          setEntity(response.data);
        });
    }
  }, [params.id]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await api.post(`/expert/add`, {
          ...data,
          id: params.id,
          cpfcnpj: data.cpfcnpj.replace(/\D/g, ""),
          mobile: data.mobile.replace(/\D/g, ""),
        });

        PopupAlert("Profissional salvo!");
        push("/expert");
      } catch (err) {
        console.error(err);
        PopupAlert(`Erro ao salvar - ${err.message}`, "error");
      }
    },
    [params.id, push]
  );

  const optionsValue = [
    {
      value: "",
      label: "---",
    },
    {
      value: "Fonodiólogo",
      label: "Fonodiólogo",
    },
    {
      value: "Psicólogo",
      label: "Psicólogo",
    },
    {
      value: "Pedagogo",
      label: "Pedagogo",
    },
    {
      value: "Terapeuta Ocupacional",
      label: "Terapeuta Ocupacional",
    },
  ];

  // Setvalues from API response
  useEffect(() => {
    Object.keys(entity).forEach((key) => {
      setValue(key, entity[key]);
    });
  }, [entity, setValue]);

  return (
    <>
      <Header />
      <Menu />
      <Main>
        <Title>
          <h2>Profissionais - Cadastro</h2>
        </Title>

        <ExpertForm onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="name"
            label="Nome"
            register={register}
            rules={{ required: true }}
            maxLength="60"
          />
          <InputMask
            name="cpfcnpj"
            label="CPF"
            mask="999.999.999-99"
            register={register}
          />
          <InputMask
            name="rg"
            label="RG"
            mask="999.999.999-9"
            register={register}
          />
          <Select
            name="specialty"
            label="Especialidade"
            options={optionsValue}
            register={register}
          />
          <InputMask
            name="mobile"
            label="Celular"
            mask="(99) 99999-9999"
            register={register}
          />
          <Input name="email" label="Email" register={register} />
          <InputMask
            name="zipcode"
            label="CEP"
            mask="99999-999"
            register={register}
          />
          <Input name="uf" label="UF" register={register} maxLength="2" />
          <Input
            name="city"
            label="Cidade"
            register={register}
            maxLength="60"
          />
          <Input
            name="neighborhood"
            label="Bairro"
            register={register}
            maxLength="60"
          />
          <Input
            name="address"
            label="Logradouro"
            register={register}
            maxLength="60"
          />
          <Input
            name="addressNumber"
            label="Número"
            register={register}
            maxLength="15"
          />
          <Input
            name="complement"
            label="Complemento"
            register={register}
            maxLength="60"
          />
          <Select
            name="bank"
            label="Banco"
            register={register}
            options={banks}
          />
          <Input name="branch" label="Agência" register={register} />
          <Input name="account" label="Conta" register={register} />
          <Input
            name="pixKey"
            label="Chave Pix"
            register={register}
            maxLength="254"
          />

          <Button gridArea="cancel" type="button" onClick={goBack}>
            Voltar
          </Button>
          <Button gridArea="save">Salvar</Button>
        </ExpertForm>
      </Main>
    </>
  );
}
