import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import logoImg from "../../assets/images/logo.png";
import { Input } from "../../components/Input";
import { Button } from "../../components/NewButton";

import { Header, Main } from "./styles";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

export default function Login() {
  const { signIn, isAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    isAuthenticated && history.push("/main");
  }, [isAuthenticated, history]);

  const onSubmit = useCallback(
    async (event) => {
      setIsLoading(true);
      await signIn({ email, password });
      setIsLoading(false);
    },
    [signIn, email, password]
  );

  return (
    <>
      <Header>
        <img src={logoImg} alt="Logo" />
      </Header>

      <Main>
        <h2>Bem vindo!</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="email"
            label="email"
            type="email"
            autoComplete="off"
            autoFocus
            required
            register={register}
            onChange={(e) => setEmail(e.target.value.trim())}
          />
          <Input
            name="password"
            label="Senha"
            type="password"
            autoComplete="off"
            required
            register={register}
            onChange={(e) => setPassword(e.target.value.trim())}
          />

          <Button isLoading={isLoading}>Entrar</Button>
        </form>
      </Main>
    </>
  );
}
