import styled from "styled-components";

export const Container = styled.div``

export const Header = styled.header`
  background: ${props => props.theme.primary};
  height: 160px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
  }
`

export const Main = styled.main`
  margin-top: 140px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 32px;
    margin-bottom: 42px;
  }

  form {
    width: 352px;
  }

  button {
    margin-top: 8px;
    width: 100%;
  }
`
